import React, { useRef } from 'react'
import { MdClose as CloseIcon } from 'react-icons/md'
import useOutsideClickListener from '../../hooks/use-outside-click-listener'
import { DrawerBox, DrawerHeader, DrawerContent } from './styled'

export default function Drawer(props) {
  const { isActive, onClose, children } = props

  const boxRef = useRef(null)
  useOutsideClickListener(boxRef, onClose)

  return (
    <DrawerBox ref={boxRef} isActive={isActive}>
      <DrawerHeader>
        <CloseIcon onClick={onClose} />
      </DrawerHeader>
      <DrawerContent>{children}</DrawerContent>
    </DrawerBox>
  )
}
