import React from 'react'
import { useEffect } from 'react'
import { css } from '@emotion/core'
// import moment from 'moment-timezone'
import SEO from '../seo'
import { colors, breakpoints, sizes } from '../../styles/variables'

export default function Careers() {
  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      '//recruitingbypaycor.com/career/iframe.action?clientId=8a7883c6828995e70182a76daac1082a'
    script.type = 'text/javascript'
    script.id = 'gnewtonjs'

    document.getElementById('paycor').appendChild(script)

    return () => {
      document.getElementById('paycor').removeChild(script)
    }
  }, [])
  return (
    <>
      <SEO title="Careers" />
      <div id="paycor"></div>
      {/* <div>
        <iframe
          allowtransparency="true"
          frameborder="0"
          scrolling="no"
          id="gnewtonIframe"
          src="https://recruitingbypaycor.com/career/CareerHome.action?clientId=8a7883c6828995e70182a76daac1082a&amp;parentUrl=https%3A%2F%2Fescaladeinc.com%2Fcareers%2F&amp;gns="
          width="100%"
        ></iframe>
      </div> */}
      {/* <div
        css={styles}
        dangerouslySetInnerHTML={{
          __html: `                  
            <script id="gnewtonjs" type="text/javascript" src="//recruitingbypaycor.com/career/iframe.action?clientId=8a7883c6828995e70182a76daac1082a"></script>
              `
        }}
      ></div> */}
    </>
  )
}

const styles = css`
  padding: 0 1rem;
  @media (${breakpoints.phoneLarge}) {
    max-width: ${sizes.constrainWidth};
    padding: 0 3rem;
    margin: auto;
  }
  div:not([class*='section']) {
    display: flex;
    flex-wrap: wrap;
  }
  h2 {
    font-size: 1.5rem;
  }
  .remote,
  .night,
  .part {
    color: ${colors.primary};
  }
  .remote {
    font-size: 0.925rem;
  }
  .night {
    font-size: 0.9rem;
  }
  .part {
    font-size: 0.975rem;
  }
  .section {
    margin-bottom: 2rem;
    h3 {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.75rem;
      width: 100%;
    }
    hr {
      margin: 2px 0;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      tr {
        td {
          padding: 10px;
          font-size: 0.65rem;
          color: ${colors.secondary};
          a {
            text-decoration: none;
            font-size: 0.65rem;
            color: ${colors.secondary};
            :hover {
              color: ${colors.primary};
            }
          }
          .remote {
            font-size: 0.825rem;
          }
          .night {
            font-size: 0.65rem;
          }
          .part {
            font-size: 0.75rem;
          }
        }
        .date {
          width: 10rem;
        }
        :nth-child(even) {
          background: ${colors.secondaryLight}80;
        }
      }
    }
  }
  @media (${breakpoints.phoneLarge}) {
    .section {
      p {
        width: 65%;
      }
    }
  }
`
