import { useEffect } from 'react'

export default function UseScrollBounce() {
  function handleScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const windowBottom = windowHeight + window.pageYOffset
    if (windowBottom >= docHeight) {
      window.scrollBy({
        top: -10,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
}
