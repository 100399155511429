import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { breakpoints, colors, sizes } from '../../styles/variables';
import axios from 'axios';

export default function StockDetail() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStockData = async () => {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
          'x-rapidapi-key': process.env.GATSBY_RAPIDAPI_KEY,
        },
      };

      try {
        const response = await axios.get(
          'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-summary?symbol=ESCA&region=US',
          options
        );
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchStockData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const {
    price,
    defaultKeyStatistics,
    calendarEvents,
    earnings,
    summaryDetail,
  } = data?.quoteSummary?.result[0] || {};

  if (!price || !summaryDetail) {
    return <div>For stock information, please visit Nasdaq</div>;
  }

  const { marketCap } = price;
  const { _52WeekChange, trailingEps } = defaultKeyStatistics;
  const { exDividendDate, dividendDate } = calendarEvents;
  const { earningsChart, financialsChart } = earnings;
  const { earningsDate } = earningsChart;
  const { yearly, quarterly } = financialsChart;

  const {
    bid,
    bidSize,
    ask,
    askSize,
    dayLow,
    dayHigh,
    fiftyTwoWeekLow,
    fiftyTwoWeekHigh,
    volume,
    averageVolume,
    trailingPE,
    dividendRate,
    dividendYield,
    previousClose,
    regularMarketOpen,
  } = summaryDetail;

  var quarter = moment(earningsDate.fmt).subtract(5, 'quarters');
  var labels = [];
  for (let i = 0; i < 4; i++) {
    labels[i] = quarter.format('[Q]Q YYYY');
    quarter.add(1, 'quarters');
  }
  const quarterlyChart = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        backgroundColor: colors.secondary,
        borderWidth: 0,
        data: quarterly.map((quarter) => quarter?.revenue.raw),
      },
      {
        label: 'Earnings',
        backgroundColor: [
          colors.primary,
          colors.primary,
          colors.primary,
          colors.primaryLight,
        ],
        borderWidth: 0,
        data: quarterly.map((quarter) => quarter?.earnings.raw),
      },
    ],
  };

  const yearlyChart = {
    labels: yearly.map((year) => year?.date),
    datasets: [
      {
        label: 'Revenue',
        backgroundColor: colors.secondary,
        borderWidth: 0,
        data: yearly.map((year) => year?.revenue.raw),
      },
      {
        label: 'Earnings',
        backgroundColor: [
          colors.primary,
          colors.primary,
          colors.primary,
          colors.primaryLight,
        ],
        borderWidth: 0,
        data: yearly.map((year) => year?.earnings.raw),
      },
    ],
  };

  return (
    <Div>
      <div className="desktop">
        <div className="left">
          <div>
            Previous Close:&nbsp;<b>{previousClose.fmt}</b>
          </div>
          <div>
            Market Open:&nbsp;<b>{regularMarketOpen.fmt}</b>
          </div>
          <div>
            Bid:&nbsp;<b>{`${bid.fmt} x ${bidSize.longFmt}`}</b>
          </div>
          <div>
            Ask:&nbsp;<b>{`${ask.fmt} x ${askSize.longFmt}`}</b>
          </div>
          <div>
            Day's Range:&nbsp;<b>{`${dayLow.fmt} - ${dayHigh.fmt}`}</b>
          </div>
          <div>
            52 Week Range:&nbsp;<b>{`${fiftyTwoWeekLow.fmt} - ${fiftyTwoWeekHigh.fmt}`}</b>
          </div>
          <div>
            52 Week Change:&nbsp;<b>{_52WeekChange.fmt}</b>
          </div>
          <div>
            Volume:&nbsp;<b>{volume.fmt}</b>
          </div>
          <div>
            Avg Volume:&nbsp;<b>{averageVolume.fmt}</b>
          </div>
          <div>
            Market Cap:&nbsp;<b>{marketCap.fmt}</b>
          </div>
          <div>
            PE Ratio (TTM):&nbsp;<b>{trailingPE.fmt}</b>
          </div>
          <div>
            EPS (TTM):&nbsp;<b>{trailingEps.fmt}</b>
          </div>
          <div>
            Earnings Date:&nbsp;<b>{earningsDate.fmt}</b>
          </div>
          <div>
            Dividend & Yield:&nbsp;<b>{`${dividendRate.fmt} (${dividendYield.fmt})`}</b>
          </div>
          <div>
            Ex-Dividend Date:&nbsp;<b>{exDividendDate.fmt}</b>
          </div>
          <div>
            Dividend Date:&nbsp;<b>{dividendDate.fmt}</b>
          </div>
          {/* <div>
            1Y Target Est:&nbsp;<b>{targetMeanPrice.fmt}</b>
          </div> */}
        </div>
        <div className="right">
          <div className="graph">
            <Bar
              data={quarterlyChart}
              width={400}
              height={250}
              options={{
                title: {
                  display: true,
                  text: 'Quarterly Revenue & Earnings',
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: 'bottom',
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
                maintainAspectRatio: false,
              }}
            />
          </div>
          <div className="graph">
            <Bar
              data={yearlyChart}
              width={400}
              height={250}
              options={{
                title: {
                  display: true,
                  text: 'Yearly Revenue & Earnings',
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: 'bottom',
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
                maintainAspectRatio: false,
              }}
            />
          </div>
        </div>
      </div>
    </Div>
  );
}

const Div = styled.div`
  @media (${breakpoints.phoneLarge}) {
    max-width: ${sizes.constrainWidth};
    margin: auto;
  }
  .desktop {
    display: flex;
    .left {
      div {
        width: 200%;
        font-size: 1rem;
        margin-right: 10rem;
      }
    }
    .right {
      width: 100%;
      graph {
        width: 75%;
      }
    }
    @media (max-width: 1380px) {
      display: block;
      .graph {
        width: 100%;
      }
    }
  }
`;
