import React from 'react'

export default function Issuu(props) {
  const { src } = props?.node

  return (
    <div
      style={{
        position: 'relative',
        paddingTop: 'max(60%,326px)',
        height: 'auto',
        width: '100%'
        // margin: 'auto'
      }}
    >
      <iframe
        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
        allowfullscreen="true"
        style={{
          position: 'absolute',
          border: 'none',
          width: '100%',
          height: '100%',
          left: '0',
          right: '0',
          top: '0',
          bottom: '0'
          // maxWidth: '1400px',
          // padding: '0 3rem',
          // margin: 'auto'
        }}
        src={`https://e.issuu.com/embed.html?d=${src}&hideIssuuLogo=true&hideShareButton=true&u=escsports`}
      />
    </div>
  )
}
