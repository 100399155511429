import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import _ from 'lodash'
import { colors, breakpoints } from '../../styles/variables'

const query = graphql`
  query cpsiaQuery {
    cpsia {
      nodes {
        name
        link
        site
      }
    }
  }
`

export default function CPSIA() {
  const queryData = useStaticQuery(query)
  const { nodes } = queryData?.cpsia

  return (
    <Div>
      {nodes.map(node => {
        return (
          <div>
            <a href={node?.link} target="_blank">
              {node?.name}
            </a>
          </div>
        )
      })}
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 75vh;
  width: 50vw;
  margin: auto;
  font-size: 0.85rem;
  div {
    width: 100%;
    text-align: center;
    a {
      color: ${colors.primary};
      text-decoration: none;
      :hover {
        color: ${colors.primaryLight};
      }
    }
    @media (${breakpoints.tablet}) {
      width: 50%;
    }
    @media (${breakpoints.laptop}) {
      width: 33.3333%;
    }
  }
`
