import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { breakpoints } from '../styles/variables'

export default function Logo(props) {
  const { image, width, altText, ...otherProps } = props

  return (
    <div css={styles(width)} className="logo">
      <Link to="/" {...otherProps}>
        <img src={image} alt={altText} />
      </Link>
    </div>
  )
}

const styles = width => css`
  a {
    height: 5rem;
  }
  img {
    ${width && `width: ${width}%;`}
    @media(${breakpoints.laptop}) {
      width: ${width || '100'}%;
    }
  }
  @media (${breakpoints.phoneLarge}) {
    flex-shrink: 0;
  }
`
