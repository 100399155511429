import React from 'react'
import Fallback from '../mobile-menu'
const Lazy = React.lazy(() => import('./lazy'))

// This has to be done this way because of the code in ./styled.js

export default function DesktopMenu(props) {
  const isSSR = typeof window === 'undefined'

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<Fallback {...props} />}>
          <Lazy {...props} />
        </React.Suspense>
      )}
    </>
  )
}
