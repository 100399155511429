import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import GatsbyImage from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'
import { breakpoints } from '../../styles/variables'

export default function ImagesPanel(props) {
  const fluids = props?.node?.images.map(image => {
    return getFluidGatsbyImage(
      image?.image?.asset?._ref || image?.image?.asset?.id,
      { maxWidth: 500, quality: 100 },
      config
    )
  })

  return (
    <Div width={props?.node?.width || 100} center={props?.node?.center}>
      {fluids.map((fluid, i) => {
        console.log(props?.node?.width || 100)
        return (
          <A
            center={props?.node?.center}
            aspectRatio={fluid?.aspectRatio}
            href={props?.node?.images[i]?.link}
            target="_blank"
          >
            <GatsbyImage
              key={i}
              fluid={fluid}
              alt={props?.node?.images[i]?.alt}
            />
          </A>
        )
      })}
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    ${props?.center && 'justify-content: center;'}
    ${props?.center && `width: ${props.width}%;`}
    @media (${breakpoints.phoneSmall}) {
      ${!props?.center && `width: ${props.width > 100 ? (props.width / 1.5) - 40 : props.width - 40}%;`}
    }
    @media (${breakpoints.phoneLarge}) {
      ${!props?.center && `width: ${props.width > 100 ? (props.width / 1.5) - 20 : props.width - 20}%;`}
    }
    @media (${breakpoints.tablet}) {
      ${!props?.center && `width: ${props.width - 10}%;`}
    }
    @media (${breakpoints.laptop}) {
      ${!props?.center && `width: ${props.width}%;`}
    }
  `
})

const A = styled.a(props => {
  const { aspectRatio:ratio, center } = props

  return css`
    display: block;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    width: ${ratio < 1.5 ? 120 : ratio * 80}%;
    height: auto;
    @media (${breakpoints.phoneSmall}) {
      width: ${ratio < 1.5 ? 100 : ratio * 50}%;
      // width: ${ratio < 1.5 ? 80 : ratio * 40}%;
      // ${center && `width: ${ratio < 1.5 ? 100 : ratio * 50}%;`}
      margin-bottom: 1rem;
    }
    @media (${breakpoints.phoneLarge}) {
      width: ${ratio < 1.5 ? 60 : ratio * 30}%;
      // width: ${ratio < 1.5 ? 40 : ratio * 20}%;
      // ${center && `width: ${ratio < 1.5 ? 60 : ratio * 30}%;`}
    }
    @media (${breakpoints.tablet}) {
      width: ${ratio < 1.5 ? 40 : ratio * 20}%;
      // width: ${ratio < 1.5 ? 20 : ratio * 10}%;
      // ${center && `width: ${ratio < 1.5 ? 40 : ratio * 20}%;`}
    }
    @media (${breakpoints.laptop}) {
      width: ${ratio < 1.5 ? 30 : ratio * 15}%;
      // width: ${ratio < 1.5 ? 10 : ratio * 5}%;
      // ${center && `width: ${ratio < 1.5 ? 30 : ratio * 15}%;`}
      ${!center &&
      `margin-right: 2rem;
      margin-bottom: 1rem;`}
    }
  `
})
