export const slideStyles = {
  'min-height': '400px',
  width: '100%',
  background: '#333',
  'font-size': '34px'
}

export const colors = {
  primary: `#0A4C98`,
  primaryLight: `#40C6F1`,
  secondary: `#4A5055`,
  secondaryLight: `#CFD2D3`,
  secondaryLighter: `#c8c8c8`,
  secondaryLightest: `#CFD2D350`,
  eagle: `#B6BAA4`,
  white: `white`,
  black: `black`,
  red: `#F72119`,
  green: `#1FFF0F`,
  grey: `#999`,
  darkGrey: `#666`
}

export const fonts = {
  primary: `Gibson, Pulp Display, Helvetica Neue, Helvetica, Liberation Sans, Arial, sans-serif`,
  secondary: `Barlow, Suprema, Helvetica Neue Extended, Verdana, Geneva, sans-serif`
}

export const shadows = {
  low: `0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)`,
  high: `0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`
}

export const padding = {
  gutters: `0 3rem 2rem 3rem`
}

export const sizes = {
  constrainWidth: `1400px`,
  constrainWidthWider: `1800px`,
  headerHeight: `6rem`,
  headerHeightMobile: `6rem`
}

export const screenWidths = {
  desktopLarge: `2560px`, // 2560x1440 (1440p screen)
  desktop: `1366px`, // 1366x768 (average 16:9 screen)
  laptop: `1024px`, //
  netbook: `900px`,
  tablet: `768px`, // 768x1024 (iPad)
  tabletSmall: `500px`,
  phoneLarge: `411px`, // 411x731 (Pixel 2)
  phoneSmall: `359px`,
  smallest: `0px`
}

// Actual breakpoints to go in media query parentheses
export const breakpoints = {
  desktopLarge: `min-width: ${screenWidths.desktopLarge}`,
  desktop: `min-width: ${screenWidths.desktop}`,
  laptop: `min-width: ${screenWidths.laptop}`,
  netbook: `min-width: ${screenWidths.netbook}`,
  tablet: `min-width: ${screenWidths.tablet}`,
  tabletSmall: `min-width: ${screenWidths.tabletSmall}`,
  phoneLarge: `min-width: ${screenWidths.phoneLarge}`,
  phoneSmall: `min-width: ${screenWidths.phoneSmall}`,
  smallest: `min-width: ${screenWidths.smallest}`
}
