import React from 'react'
import MediaCard from '../media-card'

export default function ContentCard(props) {
  const {
    force,
    body,
    image,
    bodyColor,
    backgroundColor,
    height,
    width,
    radius,
    anchor
  } = props?.node

  return force === 'left' ? (
    <MediaCard
      forceLeft={true}
      anchor={anchor}
      body={body}
      align="left"
      bodyColor={bodyColor}
      bodyBackground={backgroundColor}
      bodySize="0.7"
      imageId={image?.asset?._ref || image?.asset?.id}
      height={height}
      width={width}
      radius={radius}
    />
  ) : (
    <MediaCard
      forceRight={true}
      anchor={anchor}
      body={body}
      align="left"
      bodyColor={bodyColor}
      bodyBackground={backgroundColor}
      bodySize="0.7"
      imageId={image?.asset?._ref || image?.asset?.id}
      height={height}
      width={width}
      radius={radius}
      noPadding={true}
    />
  )
}
