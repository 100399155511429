import React from 'react'
import FeaturedContent from '../feature-content'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'

export default function FeaturedContentDisplay(props) {
  const node = props?.node

  const fluid = getFluidGatsbyImage(
    node?.media?.asset?._ref || node?.media?.asset?.id,
    { maxWidth: 400 },
    config
  )
  const featureContentProps = {
    topHeading: {
      text: node?.topHeading,
      align: node?.topHeadingAlign,
      color: node?.topHeadingColor,
      bold: node?.topHeadingBold,
      italic: node?.topHeadingItalic
    },
    heading: {
      text: node?.heading,
      align: node?.headingAlign,
      color: node?.headingColor,
      bold: node?.headingBold,
      italic: node?.headingItalic
    },
    subheading: {
      text: node?.subHeading,
      align: node?.subHeadingAlign,
      color: node?.subHeadingColor,
      bold: node?.subHeadingBold,
      italic: node?.subHeadingItalic
    },
    cardInternal: node?.cardInternal,
    bodyAlign: node?.bodyAlign,
    bodyColor: node?.bodyColor,
    media: fluid,
    altBackground: node?.altBackground,
    background: node?.background,
    height: node?.height,
    width: node?.width,
    radius: node?.radius,
    fontSize: node?.fontSize,
    content: node?.content,
    anchor: node?.anchor,
    imageRight: node?.imageRight,
    horizontalHeadings: node?.horizontalHeadings
  }

  return <FeaturedContent {...featureContentProps} />
}
