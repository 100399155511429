import React from 'react'
import { css } from '@emotion/core'
import FeatureContent from '../feature-content'
import MediaCardGrid from '../media-card-grid'
import MediaCard from '../media-card'
import { sizes, colors } from '../../styles/variables'

export default function FeaturedStrip(props) {
  const {
    heading,
    anchor,
    isSubset,
    imageTitleOverlay,
    forcedTitleHeight,
    forcedSubTitleHeight,
    forcedCaptionHeight,
    forcedBodyHeight,
    featuredCards
  } = props?.node

  return (
    <>
      <FeatureContent
        centeredContent={true}
        heading={{ text: heading, color: colors.primary, align: `center` }}
      />
      <MediaCardGrid
        id={anchor}
        css={css`
          ${gridStyles}
          ${isSubset &&
          css`
            div:first-child,
            a:first-child {
              margin-left: inherit;
            }
            div,
            a {
              margin-left: 50px;
              padding: 5px;
            }
          `}
        `}
      >
        {featuredCards.map((card, i) => {
          const {
            _key,
            heading,
            subHeading,
            caption,
            body,
            align,
            imageAlt,
            image,
            height,
            width,
            radius,
            url,
            link,
            linkText,
            linkStyle,
            externalMessage,
            headingColor,
            subHeadingColor,
            captionColor,
            bodyColor,
            headingBackground,
            subHeadingBackground,
            captionBackground,
            bodyBackground,
            externalLink
          } = card || {}
          
          return (
            <MediaCard
              key={_key}
              i={i}
              title={heading}
              subTitle={subHeading}
              caption={caption}
              body={body}
              align={align}
              link={link}
              linkText={linkText}
              linkStyle={linkStyle}
              url={url}
              imageTitleOverlay={imageTitleOverlay}
              forcedTitleHeight={forcedTitleHeight}
              forcedSubTitleHeight={forcedSubTitleHeight}
              forcedCaptionHeight={forcedCaptionHeight}
              forcedBodyHeight={forcedBodyHeight}
              externalMessage={externalMessage}
              alt={imageAlt}
              imageId={image?.asset?._ref || image?.asset?.id}
              height={height}
              width={width}
              radius={radius}
              titleColor={headingColor}
              subTitleColor={subHeadingColor}
              captionColor={captionColor}
              bodyColor={bodyColor}
              titleBackground={headingBackground}
              subTitleBackground={subHeadingBackground}
              captionBackground={captionBackground}
              bodyBackground={bodyBackground}
              externalLink={externalLink}
              titleSize="1"
              subTitleSize="0.8"
              captionSize="0.6"
              bodySize="0.7"
            />
          )
        })}
      </MediaCardGrid>
    </>
  )
}

const gridStyles = css`
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: ${sizes.constrainWidth};
  padding: 0 3rem;
  a {
    text-decoration: none;
  }
`
