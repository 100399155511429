import React, { useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import _ from 'lodash'
import { FaDownload } from 'react-icons/fa'
import BlockContent from './block-content'
import { colors, fonts, breakpoints, sizes } from '../styles/variables'

export default function SectionedContent({ page }) {
  const [selectedMix, setSelectedMix] = useQueryParam('mix', StringParam)
  const [selectedMixNoNav, setSelectedMixNoNav] = useState('')

  function formatString(str) {
    return str.replace(/\s+/g, '-').replace(/&+/g, 'and').toLowerCase()
  }

  function getSelectedMix(menu) {
    switch (navigation) {
      case true:
        return menu.find(mix => formatString(mix?.title) === selectedMix) || ''
      case false:
        return (
          menu.find(mix => formatString(mix?.title) === selectedMixNoNav) || ''
        )
      default:
        return (
          menu.find(mix => formatString(mix?.title) === selectedMixNoNav) || ''
        )
    }
  }

  const {
    heading,
    showHeading,
    anchor,
    navigation,
    autoPopulate,
    splashPage
  } = page

  let menu = _.cloneDeep(page?.content)

  useEffect(() => {
    if (autoPopulate) {
      switch (navigation) {
        case true:
          if (!selectedMix || (selectedMix && !getSelectedMix(menu))) {
            setSelectedMix(formatString(menu[0].title))
          }
          break
        case false:
          setSelectedMixNoNav(formatString(menu[0].title))
          break
        default:
          setSelectedMixNoNav(formatString(menu[0].title))
      }
    }
  }, [])

  const mixContent = getSelectedMix(menu)

  return (
    <Div id={anchor} showHeading={showHeading} mixContent={mixContent}>
      <div className="outer-menu">
        {showHeading && (
          <div className="title">
            <h1>
              {splashPage ? (
                <a className="header-link" href={`/${heading.toLowerCase()}`}>
                  {heading}
                </a>
              ) : (
                heading
              )}
            </h1>
          </div>
        )}
        <div className="mixed-menu">
          {menu.map(element => {
            return (
              <div
                className={`item ${
                  (formatString(element.title) === selectedMix ||
                    formatString(element.title) === selectedMixNoNav) &&
                  `selected`
                }`}
              >
                {element._type === 'mixedEntry' ? (
                  <span
                    onClick={() => {
                      switch (navigation) {
                        case true:
                          setSelectedMix(formatString(element.title))
                          break
                        case false:
                          setSelectedMixNoNav(formatString(element.title))
                          break
                      }
                    }}
                  >
                    <b>{element.title}</b>
                  </span>
                ) : (
                  <>
                    <span
                      className="name"
                      onClick={() => {
                        switch (navigation) {
                          case true:
                            setSelectedMix(formatString(element.title))
                            break
                          case false:
                            setSelectedMixNoNav(formatString(element.title))
                            break
                        }
                      }}
                    >
                      <b>
                        <a
                          href={`${element?.file?.asset?.url}#toolbar=0`}
                          target="_blank"
                        >
                          {element.title}
                        </a>
                      </b>
                    </span>
                    <span className="download">
                      <a
                        href={`${element?.file?.asset?.url}?dl=${element?.file?.asset?.originalFilename}`}
                      >
                        <FaDownload />
                      </a>
                    </span>
                  </>
                )}
              </div>
            )
          })}
          <br />
        </div>
      </div>
      {mixContent && mixContent._type === 'mixedEntry' && (
        <div className="content">
          {mixContent?.showTitle && (
            <div className="header">{mixContent?.title}</div>
          )}
          <div className="copy">
            <BlockContent
              renderContainerOnSingleChild
              blocks={mixContent?.mixedContent}
            />
          </div>
        </div>
      )}
      {splashPage !== undefined &&
        splashPage.length !== 0 &&
        !autoPopulate &&
        ((navigation && typeof selectedMix === 'undefined') ||
          (!navigation && typeof selectedMixNoNav === 'undefined')) && (
          <div className="content">
            <div className="copy">
              <BlockContent renderContainerOnSingleChild blocks={splashPage} />
            </div>
          </div>
        )}
    </Div>
  )
}

const Div = styled.div(
  props => css`
    @media (${breakpoints.phoneLarge}) {
      padding: 0 1rem;
      max-width: ${sizes.constrainWidth};
      margin: auto;
    }
    .outer-menu {
      display: block;
      .title {
        margin-bottom: 1rem;
        font-style: ${fonts.primary};
        font-weight: bolder;
        font-size: 1rem;
        color: black;
        text-align: left;
        .header-link {
          text-decoration: none;
          color: black;
        }
      }
      .mixed-menu {
        display: block;
        font-size: 1rem;
        color: black;
        width: 100%;
        margin-bottom: 0.5rem;
        ${props?.mixContent?._type === 'mixedEntry' &&
        `border-bottom: 1px solid black;`}
        a {
          color: black;
          text-decoration: none;
        }
        .item {
          span {
            margin-right: 0.1rem;
            padding: 0 3px 0 3px;
            transition: 0.25s;
            cursor: pointer;
            margin-right: 10px;
          }
        }
        .item:hover {
          span:hover {
            color: ${colors.primary};
          }
          .name:hover {
            a {
              color: ${colors.primary};
            }
          }
          .download:hover {
            a {
              color: ${colors.primary};
            }
          }
        }
        .selected {
          color: ${colors.primary};
          transition: 0s;
          a {
            color: ${colors.primary};
          }
          .download {
            a {
              color: black;
            }
          }
        }
      }
    }
    .content {
      margin-left: 0;
      width: 100%;
    }
  `
)
