import React from 'react'
import Image from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Carousel from '../carousel'
import Video from './video'
import config from '../../../../config'
import { slideStyles } from '../../styles/variables'

export default function Slider(props) {
  const { slide: panels, hideDots } = props?.node

  function getSlides(panels) {
    const slides = panels.map(panel => {
      switch (panel?._type) {
        case 'image':
          return {
            type: 'image',
            fluid: getFluidGatsbyImage(
              panel?.asset?._ref || panel?.asset?.id,
              { maxWidth: 1200 },
              config
            )
          }
        case 'slideVideo':
          return {
            type: 'video',
            url: panel?.url
          }
      }
    })
    return slides
  }

  const slides = getSlides(panels)

  return (
    <Carousel hideDots={hideDots}>
      {slides.map(slide => {
        switch (slide?.type) {
          case 'image':
            return <Image css={slideStyles} fluid={slide?.fluid} />
          case 'video':
            return (
              <Video
                node={{
                  video: slide?.url,
                  videoAlign: 'center',
                  videoSize: 'small'
                }}
              />
            )
        }
      })}
    </Carousel>
  )
}
