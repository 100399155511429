import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import GatsbyImage from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'
import BlockContent from '../block-content'
import { sizes, breakpoints, fonts } from '../../styles/variables'

export default function Banner(props) {
  const {
    topHeading,
    topHeadingColor,
    heading,
    superscript,
    headingColor,
    subHeading,
    subHeadingColor,
    subHeadingColorBar,
    body,
    bodyColor,
    align,
    center,
    wide,
    image,
    overlay,
    opacity,
    overlayColor,
    bold,
    italic,
    anchor
  } = props?.node

  const fluid = getFluidGatsbyImage(
    image?.asset?._ref || image?.asset?.id,
    { maxWidth: 5000 },
    config
  )

  return (
    <Div
      id={anchor}
      wide={wide}
      align={align}
      center={center}
      topColor={topHeadingColor}
      headColor={headingColor}
      subColor={subHeadingColor}
      bodyColor={bodyColor}
      bar={subHeadingColorBar}
      overlay={overlay}
      opacity={opacity}
      overlayColor={overlayColor}
      bold={bold}
      italic={italic}
    >
      <div className={align || 'center'}>
        {topHeading && <div className="topHeading">{topHeading}</div>}
        {heading && (
          <div className="heading">
            {heading}
            {superscript && (
              <>
                &nbsp;<sup>{superscript}</sup>
              </>
            )}
          </div>
        )}
        {(subHeading || subHeadingColorBar) && (
          <span className="blocked">
            {subHeadingColorBar && (
              <>
                <div className="bar" />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </>
            )}
            {subHeading && (
              <span className="subHeading">
                <b>{subHeading}</b>
              </span>
            )}
          </span>
        )}
        {body && <BlockContent className="body" blocks={body} />}
      </div>
      <GatsbyImage className="image" fluid={fluid} />
      {overlay && <div className="overlay" />}
    </Div>
  )
}

const Div = styled.div(props => {
  const {
    wide,
    align,
    center,
    topColor,
    headColor,
    subColor,
    bodyColor,
    bar,
    opacity,
    overlayColor,
    bold,
    italic
  } = props

  return css`
    position: relative;
    text-align: ${center ? 'center' : 'left'};
    padding: 0 3rem;
    max-width: ${sizes.constrainWidth};
    ${wide &&
    `
            padding: 0;
            max-width: none;
        `}
    div {
      color: white;
      z-index: 1;
    }
    .blocked {
      ${center && 'margin: auto;'}
      margin-bottom: 5px;
      @media (${breakpoints.netbook}) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }
    .image {
      z-index: -1;
      opacity: 0.3;
      @media (${breakpoints.desktop}) {        
        opacity: unset;
      }      
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      opacity: ${opacity || '0.3'};
      width: 100%;
      height: 100%;
      background: ${overlayColor || 'black'};
      z-index: 0;
    }
    .topHeading {
      color: ${topColor};
      font-size: 0.75rem;
      margin-bottom: 5px;
    }
    .heading {
      @media (${breakpoints.phoneLarge}) {        
        font-size: 1.4rem;
      }
      color: ${headColor};
      font-size: 2rem;
      margin-bottom: 10px;
      line-height: 30px;
      ${bold && `font-family: ${fonts.primary};`}
      font-style: ${italic ? 'italic' : 'normal'};
      font-weight: ${bold ? '900' : 'normal'};
      sup {
        position: relative;
        top: -7px;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
    .subHeading {
      visibility: hidden;
      color: ${subColor};
      @media (${breakpoints.phoneLarge}) {
        visibility: visible;
      }
    }
    .body {
      visibility: hidden;
      color: ${bodyColor};
      font-size: 0.65rem;
      width: 100%;
      ${center && 'margin: auto;'}
      a {
        color: ${bodyColor};
      }
      @media (${breakpoints.tablet}) {
        visibility: visible;
      }

      @media (${breakpoints.laptop}) {
        font-size: 0.75rem;
        width: 100%;
        ${align === 'left' || align === 'right' ? 'width: 35%;' : 'width: 75%;'}
      }
    }
    .bar {
      display: none;
      background: ${bar};
      width: 150px;
      height: 5px;
      @media (${breakpoints.phoneLarge}) {
        display: initial;
      }
      ${center && 'width: 0;'}
    }
    .left {
      position: absolute;
      top: 25%;
      left: 5%;
    }
    .center {
      position: absolute;
      top: 25%;
      left: 5%;
      @media (${breakpoints.laptop}) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .right {
      position: absolute;
      top: 25%;
      left: 5%;
      @media (${breakpoints.laptop}) {
        top: 5%;
        left: 75%;
      }
    }
  `
})
