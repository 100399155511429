import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import Tile from './tile'
import { breakpoints, sizes } from '../../../styles/variables'

const query = graphql`
  query CategoryGridQuery {
    allSanityCategory(sort: { fields: [order], order: ASC }) {
      nodes {
        _id
        name
        order
        link {
          current
        }
        description: _rawDescription
        tileImage {
          asset {
            _id
          }
        }
        brands {
          _key
          name
          hide
          width
          description
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default function CategoryGrid() {
  const queryData = useStaticQuery(query)
  const categories = queryData?.allSanityCategory?.nodes.sort((a, b) => {
    return a.order - b.order
  })
  if (!categories?.length) return null

  return (
    <div css={styles}>
      {categories.map((category, i) => {
        return (
          <Tile
            key={category._id}
            name={category?.name}
            description={category?.description}
            brands={category?.brands}
            url={category?.link?.current}
            tileImage={category?.tileImage}
          />
        )
      })}
    </div>
  )
}

const styles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: auto;
  padding: 2rem 3rem;
  max-width: ${sizes.constrainWidthWider};
`
