import React from 'react'
import SanityBlockContent from '@sanity/block-content-to-react'
import * as types from './block-types'

export default function BlockContent(props) {
  const { blocks, ...other } = props
  if (!blocks?.length) return null

  return (
    <SanityBlockContent
      renderContainerOnSingleChild
      blocks={blocks}
      serializers={{ types }}
      {...other}
    />
  )
}
