import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export default function VerticalLogos(props) {
  const { brands, name } = props
  return (
    <Div key={name} brands={brands} className="hover">
      {brands.map(brand => {
        return (
          !brand?.hide && (
            <>
              <img
                className="vlogo"
                id={`id-${brand._key}`}
                key={brand._key}
                src={brand.logo.asset.url}
                alt={brand.name}
              />
              <div className="break" />
            </>
          )
        )
      })}
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: center;
    .break {
      flex-basis: 100%;
    }
    img:not(:last-child) {
      margin-bottom: 1.65rem;
    }
    ${props?.brands.map(brand => {
      return `
                .vlogo#id-${brand._key} {
                    width: ${brand?.width || '25'}%;
                }
            `
    })}
  `
})
