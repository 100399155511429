import React from 'react'
import Button from '../button'

export default function ButtonDisplay(props) {
  const {
    link,
    text,
    outlined,
    align,
    buttonStyle,
    hoverTextColor,
    ...otherProps
  } = props?.node
  function getSecondaryLight() {
    let secondary
    let light
    switch (buttonStyle) {
      case 'primary':
        secondary = false
        light = false
        break
      case 'secondary':
        secondary = true
        light = false
        break
      case 'light':
        secondary = false
        light = true
        break
      default:
        secondary = false
        light = false
        break
    }
    return { secondary, light }
  }
  const { secondary, light } = getSecondaryLight()

  const button = {
    link: link,
    text: text,
    align: align,
    hoverTextColor: hoverTextColor,
    outlined,
    secondary,
    light,
    ...otherProps
  }

  return <Button {...button} />
}
