import React from 'react'
import { css } from '@emotion/core'
import SanityBlockContent from '@sanity/block-content-to-react'
import FeatureContent from '../feature-content'
import { breakpoints, sizes, padding, colors } from '../../styles/variables'

export default function CenteredHeadingAndContent(props) {
  const {
    topHeading,
    topHeadingColor,
    topHeadingBold,
    topHeadingItalic,
    heading,
    headingColor,
    headingBold,
    headingItalic,
    subHeading,
    subHeadingColor,
    subHeadingBold,
    subHeadingItalic,
    content,
    anchor
  } = props?.node || {}

  const featureContentProps = {
    topHeading: {
      text: topHeading,
      color: topHeadingColor || colors.primary,
      align: `center`,
      bold: topHeadingBold,
      italic: topHeadingItalic
    },
    heading: {
      text: heading,
      color: headingColor || colors.primary,
      align: `center`,
      bold: headingBold,
      italic: headingItalic
    },
    subheading: {
      text: subHeading,
      color: subHeadingColor || colors.primary,
      align: `center`,
      bold: subHeadingBold,
      italic: subHeadingItalic
    },
    centeredContent: true
  }

  return (
    <div id={anchor}>
      <FeatureContent css={styles} {...featureContentProps}>
        <SanityBlockContent
          className="centered-content-container"
          renderContainerOnSingleChild
          blocks={content}
        />
        {props.children}
      </FeatureContent>
    </div>
  )
}

const styles = css`
  text-align: center;
  padding: 0 1rem;
  @media (${breakpoints.phoneLarge}) {
    max-width: ${sizes.constrainWidth};
    padding: 0 3rem;
    margin: auto !important;
  }

  p {
    font-size: 1rem;
    line-height: 1.75;
    margin-bottom: 2rem;
  }

  @media (${breakpoints.tablet}) {
    padding: 0 9rem;
  }
`
