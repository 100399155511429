import React, { useState, useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import _ from 'lodash'
import BlockContent from './block-content'
import { colors, fonts, breakpoints, sizes } from '../styles/variables'

export default function SectionedContent({ page }) {
  const [selectedSection, setSelectedSection] = useQueryParam(
    'section',
    StringParam
  )
  const [selectedSectionNoNav, setSelectedSectionNoNav] = useState('')

  const {
    heading,
    showHeading,
    anchor,
    navigation,
    autoPopulate,
    splashPage,
    hasSubMenu
  } = page

  let menu = _.cloneDeep(page?.content)

  function formatString(str) {
    return str.replace(/\s+/g, '-').replace(/&+/g, 'and').toLowerCase()
  }

  function getSelectedSection(menu) {
    switch (navigation && !hasSubMenu) {
      case true:
        return (
          menu.find(
            section => formatString(section?.title) === selectedSection
          ) || ''
        )
      case false:
        return (
          menu.find(
            section => formatString(section?.title) === selectedSectionNoNav
          ) || ''
        )
      default:
        return (
          menu.find(
            section => formatString(section?.title) === selectedSectionNoNav
          ) || ''
        )
    }
  }

  useEffect(() => {
    if (autoPopulate) {
      switch (navigation) {
        case true:
          if (
            !selectedSection ||
            (selectedSection && !getSelectedSection(menu))
          ) {
            setSelectedSection(formatString(menu[0].title))
          }
          break
        case false:
          setSelectedSectionNoNav(formatString(menu[0].title))
          break
        default:
          setSelectedSectionNoNav(formatString(menu[0].title))
      }
    }
  }, [])

  const sectionContent = getSelectedSection(menu)

  return (
    <Div id={anchor} {...page}>
      <div className="outer-menu">
        {showHeading && (
          <div className="title">
            <h1>
              {splashPage ? (
                <a className="header-link" href={`/${heading.toLowerCase()}`}>
                  {heading}
                </a>
              ) : (
                heading
              )}
            </h1>
          </div>
        )}
        <div className="section-menu" id="menu">
          {menu.map(element => {
            return (
              <span
                className={`item ${
                  (formatString(element.title) === selectedSection ||
                    formatString(element.title) === selectedSectionNoNav) &&
                  `selected`
                }`}
                onClick={() => {
                  switch (navigation) {
                    case true:
                      setSelectedSection(formatString(element.title))
                      break
                    case false:
                      setSelectedSectionNoNav(formatString(element.title))
                      break
                    default:
                      setSelectedSectionNoNav(formatString(element.title))
                      break
                  }
                }}
              >
                <b>{element.title}</b>
              </span>
            )
          })}
        </div>
      </div>
      {sectionContent && (
        <div className="content-section">
          {sectionContent?.showTitle && (
            <div className="header">{sectionContent?.title}</div>
          )}
          <div className="copy">
            <BlockContent
              renderContainerOnSingleChild
              blocks={sectionContent?.sectionedContent}
            />
          </div>
        </div>
      )}
      {splashPage !== undefined &&
        splashPage.length !== 0 &&
        !autoPopulate &&
        ((navigation && typeof selectedSection === 'undefined') ||
          (!navigation && typeof selectedSectionNoNav === 'undefined')) && (
          <div className="content-section">
            <div className="copy">
              <BlockContent renderContainerOnSingleChild blocks={splashPage} />
            </div>
          </div>
        )}
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    ${!props.backgroundColor &&
    `
      padding: 0 3rem;
      max-width: ${sizes.constrainWidth};
      margin: auto;
    `}

    .outer-menu {
      background: ${props?.backgroundColor || 'transparent'};
      ${props.hasSubMenu && `border-bottom: 1px solid black;`}
      ${props?.backgroundColor && 'padding: 20px;'}
      display: block;
      .title {
        margin-bottom: 1rem;
        font-style: ${fonts.primary};
        font-weight: bolder;
        font-size: 1rem;
        color: black;
        text-align: left;
        .header-link {
          text-decoration: none;
          color: black;
        }
      }
      .section-menu {
        display: flex;
        flex-wrap: wrap;
        ${props.centered && 'justify-content: center;'}
        font-size: 0.75rem;
        color: ${props?.forwardColor || colors.secondary};
        width: 100%;
        height: auto;
        .item {
          margin-right: 0.1rem;
          height: 1.5rem;
          padding: 0 3px 0 3px;
          cursor: pointer;
          transition: 0.25s;
          ${props?.backgroundColor &&
          `
            padding: 0 20px;
          `}
        }
        .item:hover {
          color: ${props?.forwardHoverColor || colors.secondaryLight};
          background: ${props?.backgroundColor
            ? props?.backgroundHoverColor
            : props?.backgroundHoverColor || colors.secondary};
        }
        ${!props?.backgroundColor &&
        `
          b::before {
            content: "| ";
            font-size: 0.85rem;
          }
          b::after {
            content: " |";
            font-size: 0.85rem;
          }
        `}
        .selected {
          color: ${props?.forwardHoverColor || colors.secondaryLight};
          background: ${props?.backgroundColor
            ? props?.backgroundHoverColor
            : props?.backgroundHoverColor || colors.secondary};
          // font-size: 1.25rem;
          // b::before, b::after {
          //   font-size: 1.35rem;
          // }
        }
      }
    }
    .content-section {
      @media (${breakpoints.phoneLarge}) {
        ${props?.backgroundColor &&
        !props?.hasSubMenu &&
        `
          padding: 0 3rem;
          max-width: ${sizes.constrainWidth};
          margin: auto;
        `}
      }
      // margin-top: 6rem;
      margin-left: 0;
      width: 100%;
      // @media (${breakpoints.tablet}) {
      //   margin-top: 2rem;
      // }
      // @media (${breakpoints.laptop}) {
      //   margin-top: 1rem;
      // }
      ${props?.expandedContent &&
      `
        @media(${breakpoints.laptop}) {
          width: 115%;
        }
        @media(${breakpoints.desktop}) {
          width: 135%;
        }
        @media(${breakpoints.desktopLarge}) {
          width: 155%;
        }
      `}
    }
  `
})
