import React from 'react'
import { css } from '@emotion/core'
import { sizes } from '../../styles/variables'

export default function iFrame(props) {
  const { src, constrain, border } = props?.node
  return (
    <div css={styles({ constrain, border })}>
      <iframe src={src} allowTransparency="true" />
    </div>
  )
}

const styles = props => css`
  ${props?.constrain &&
  `
        padding: 0 3rem;
        max-width: ${sizes.constrainWidth};
        margin: auto;
    `}
  iframe {
    width: 100%;
    height: 100vh;
    background: transparent;
    ${!props?.border && 'border: none;'}
  }
`
