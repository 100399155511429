import React, { useState } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Modal from './modal'
import Logo from './logo'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { DividerBottom } from './divider'
import { colors, sizes, breakpoints } from '../styles/variables'
import IconLight from '../../static/icon-light.svg'
import IconDark from '../../static/icon-dark.svg'
import config from '../../../config'
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest,
  FaLinkedin,
  // FaTiktok
} from 'react-icons/fa'

export default function Footer({ queryData }) {
  const [icon, setIcon] = useState(false)

  function getIcon() {
    let ico
    switch (icon) {
      case false:
        ico = IconLight
        break
      case true:
        ico = IconDark
        break
      default:
        ico = IconLight
        break
    }
    return (
      <img
        src={ico}
        alt="E"
        onMouseEnter={() => setIcon(true)}
        onMouseLeave={() => setIcon(false)}
      />
    )
  }

  const {
    footerLogo,
    navigation,
    linksOn,
    site,
    facebook,
    twitter,
    instagram,
    youtube,
    pinterest,
    linkedin,
    // tiktok
  } = queryData?.allSanitySiteSettings?.nodes[0]

  const fluid = getFluidGatsbyImage(
    footerLogo?.asset?._ref,
    { maxWidth: 1000 },
    config
  )

  const socialLinks = [
    { link: facebook, icon: FaFacebookSquare },
    { link: twitter, icon: FaTwitter },
    { link: instagram, icon: FaInstagram },
    { link: youtube, icon: FaYoutube },
    { link: pinterest, icon: FaPinterest },
    { link: linkedin, icon: FaLinkedin },
    // { link: tiktok, icon: FaTiktok }
  ]

  return (
    <footer css={styles}>
      <div className="footer-content">
        <div className="upper">
          <Logo
            className="footer-logo"
            image={fluid?.src}
            width={50}
            altText="Escalade Inc."
          />
          {linksOn && (
            <div className="social">
              <div>
                <a key={`social-${0}`} href={site} target="_blank">
                  {getIcon()}
                </a>
              </div>
              {socialLinks.map((link, index) => {
                const Icon = link.icon
                return (
                  link.link && (
                    <div>
                      <a
                        key={`social-${index + 1}`}
                        href={link.link}
                        target="_blank"
                      >
                        <Icon />
                      </a>
                    </div>
                  )
                )
              })}
            </div>
          )}
        </div>
        <nav>
          {navigation.map((navItem, i) => {
            const { text, url, hide, subLinks } = navItem || {}

            return (
              !hide && (
                <div key={`footer-url-${i}`} className="url-group">
                  <Link className="heading" to={url}>
                    <b>{text}</b>
                  </Link>
                  {subLinks?.map?.((subLink, i2) => {
                    return !subLink?.newTab && !subLink?.externalLink ? (
                      <AnchorLink
                        key={`footer-url-${i}-${i2}`}
                        to={subLink?.url}
                      >
                        <b>{subLink?.text}</b>
                      </AnchorLink>
                    ) : !subLink?.externalLink ? (
                      <a
                        key={`footer-url-${i}-${i2}`}
                        href={subLink?.url}
                        target="_blank"
                      >
                        <b>{subLink?.text}</b>
                      </a>
                    ) : (
                      <Modal
                        to={subLink?.url}
                        externalMessage={subLink?.externalMessage}
                        fontSize={0.9}
                        component={
                          <a
                            key={`footer-url-${i}-${i2}`}
                            href={subLink?.url}
                            target="_blank"
                          >
                            <b>{subLink?.text}</b>
                          </a>
                        }
                      />
                    )
                  })}
                </div>
              )
            )
          })}
        </nav>
      </div>
      <hr />
      <DividerBottom>
        <span>
          ©&nbsp;{new Date().getFullYear()}
          &nbsp;Escalade&nbsp;Inc.&emsp;&emsp;&emsp;&emsp;
        </span>
        <span>
          <Link to="/privacy-policy">Privacy&nbsp;Policy</Link>
          &emsp;&emsp;|&emsp;&emsp;
        </span>
        <span>
          <Link to="/terms-of-use">Terms&nbsp;of&nbsp;Use</Link>
          &emsp;&emsp;|&emsp;&emsp;
        </span>
        <span>
          <Link to="/accessibility-statement">
            Accessibility&nbsp;Statement
          </Link>
          &emsp;&emsp;|&emsp;&emsp;
        </span>
        <span>
          <Link to="/cpsia-certifications">CPSIA&nbsp;Certifications</Link>
          &emsp;&emsp;|&emsp;&emsp;
        </span>
        <span>
          <Link to="/patents">Patents</Link>
        </span>
      </DividerBottom>
    </footer>
  )
}

const styles = css`
  width: 100%;
  background: ${colors.primary};
  color: ${colors.secondaryLight};
  hr {
    margin: 0 1rem;
  }

  span {
    text-decoration: none;
    color: white;
    a {
      color: white;
    }
    font-size: 0.35rem;
    @media (${breakpoints.tablet}) {
      font-size: 0.4rem;
    }
    @media (${breakpoints.laptop}) {
      font-size: 0.65rem;
    }
  }

  .footer-content {
    max-width: ${sizes.constrainWidth};
    padding: 0 3rem 2rem;
    margin: 0 auto;
  }

  .upper {
    display: none;
  }

  .footer-logo {
    display: none;
    flex: 1;
    width: 50%;
    max-width: 20rem;
    margin-left: 2%;
    // margin-right: 3rem;
  }

  nav {
    display: none;

    a,
    div {
      display: block;
      margin-bottom: 0.25rem;
      text-decoration: none;
      font-size: 0.8rem;
      color: ${colors.white};

      &.heading {
        text-transform: uppercase;
        font-size: 1.05rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .url-group {
    // width: 100%;
    a,
    div b {
      cursor: pointer;
      transition: 0.4s;
      :hover {
        color: ${colors.secondaryLight};
      }
      @media (${breakpoints.tablet}) {
        font-size: 0.4rem;
      }
      @media (${breakpoints.laptop}) {
        font-size: 0.65rem;
      }
    }
    .heading {
      @media (${breakpoints.tablet}) {
        font-size: 0.65rem;
      }
      @media (${breakpoints.laptop}) {
        font-size: 0.9rem;
      }
    }
  }

  .url-group:not(:last-child) {
    margin-right: 3rem;
  }

  .social {
    display: none;
    color: ${colors.secondaryLight};
    height: 75px;
    margin-left: 150px;

    img,
    svg {
      // margin-top: 100px;
      display: block;
      // border: 2px solid ${colors.secondaryLight};
      padding: 10px;
      // border-radius: 100%;
      width: 50px;
      height: 50px;
      color: ${colors.white};
      transition: 0.4s;
    }

    img:hover,
    svg:hover {
      // background: ${colors.secondaryLight};
      color: ${colors.secondaryLight};
    }
  }

  @media (${breakpoints.laptop}) {
    .footer-logo {
      display: block;
      margin-right: 27.5vw;
    }

    .upper {
      display: flex;
    }

    nav {
      display: flex;
      justify-content: center;
    }

    .social {
      display: flex;
    }
  }
`
