import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import GatsbyImage from 'gatsby-image'
import BlockContent from '../block-content'
import Modal from '../modal'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'
import { colors, sizes, breakpoints } from '../../styles/variables'

export default function GridContent(props) {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    window && setWidth(window.innerWidth)
  })

  const gridElements = _.chunk(props?.node?.gridElements, 2)
  const { fullWidth } = props?.node

  return (
    <Div fullWidth={fullWidth}>
      {gridElements.map(elements => {
        const { _type } = elements[0]

        if (_type === 'image') {
          const { asset } = elements[0]
          const {
            heading,
            body,
            caption,
            url,
            urlText,
            colorBar,
            externalLink,
            externalMessage
          } = elements[1]

          const fluid = getFluidGatsbyImage(
            asset?._ref || asset?.id,
            { maxWidth: 500, quality: 100 },
            config
          )

          return (
            <div className="block">
              <GatsbyImage className="image" fluid={fluid} />
              <Block {...elements[1]} fullWidth={fullWidth}>
                <div className="header">{heading}</div>
                <BlockContent className="body" blocks={body} />
                <div className="link-grid">
                  {!externalLink ? (
                    <Link to={url}>
                      <i>{urlText || 'LEARN MORE'}</i>
                    </Link>
                  ) : (
                    <Modal
                      cursor="pointer"
                      className="link"
                      to={url}
                      externalMessage={externalMessage}
                      component={
                        <a>
                          <i>{urlText || 'LEARN MORE'}</i>
                        </a>
                      }
                    />
                  )}
                </div>
                {colorBar && <div className="bar-grid" />}
                <div className="caption-grid">
                  <b>{caption}</b>
                </div>
              </Block>
            </div>
          )
        } else if (_type === 'gridText') {
          // This is the specific screen width at which the component shifts into a single column
          if (width >= 768) {
            const { asset } = elements[1]
            const {
              heading,
              body,
              caption,
              url,
              urlText,
              colorBar,
              externalLink,
              externalMessage
            } = elements[0]

            const fluid = getFluidGatsbyImage(
              asset?._ref || asset?.id,
              { maxWidth: 500, quality: 100 },
              config
            )

            return (
              <div className="block">
                <Block {...elements[0]} fullWidth={fullWidth}>
                  <div className="header">{heading}</div>
                  <BlockContent className="body" blocks={body} />
                  <div className="link-grid">
                    {!externalLink ? (
                      <Link to={url}>
                        <i>{urlText || 'LEARN MORE'}</i>
                      </Link>
                    ) : (
                      <Modal
                        cursor="pointer"
                        className="link"
                        to={url}
                        externalMessage={externalMessage}
                        component={
                          <a>
                            <i>{urlText || 'LEARN MORE'}</i>
                          </a>
                        }
                      />
                    )}
                  </div>
                  {colorBar && <div className="bar-grid" />}
                  <div className="caption-grid">
                    <b>{caption}</b>
                  </div>
                </Block>
                <GatsbyImage className="image" fluid={fluid} />
              </div>
            )
          } else {
            const { asset } = elements[1]
            const {
              heading,
              body,
              caption,
              url,
              urlText,
              colorBar,
              externalLink,
              externalMessage
            } = elements[0]

            const fluid = getFluidGatsbyImage(
              asset?._ref || asset?.id,
              { maxWidth: 2000, quality: 100 },
              config
            )

            return (
              <div className="block">
                <GatsbyImage className="image" fluid={fluid} />
                <Block {...elements[0]} fullWidth={fullWidth}>
                  <div className="header">{heading}</div>
                  <BlockContent className="body" blocks={body} />
                  <div className="link-grid">
                    {!externalLink ? (
                      <Link to={url}>
                        <i>{urlText || 'LEARN MORE'}</i>
                      </Link>
                    ) : (
                      <Modal
                        cursor="pointer"
                        className="link"
                        to={url}
                        externalMessage={externalMessage}
                        component={
                          <a>
                            <i>{urlText || 'LEARN MORE'}</i>
                          </a>
                        }
                      />
                    )}
                  </div>
                  {colorBar && <div className="bar-grid" />}
                  <div className="caption-grid">
                    <b>{caption}</b>
                  </div>
                </Block>
              </div>
            )
          }
        }
      })}
    </Div>
  )
}

const Block = styled.div(props => {
  const {
    background,
    align,
    headingColor,
    bodyColor,
    urlColor,
    colorBar,
    fullWidth
  } = props

  return css`
    width: 250px;
    height: auto;
    ${fullWidth &&
    css`
      width: 100vw;
      @media (${breakpoints.tablet}) {
        width: 50vw;
        height: 50vw;
        padding-left: 50px;
      }
      // @media(${breakpoints.tablet}) {
      //     height: 50vw;
      //     padding-left: 50px;
      // }
      @media (min-width: 1135px) {
        padding-top: 150px;
      }
      height: auto;
    `}
    padding: 20px;
    background: ${background || colors.black};
    text-align: ${align || 'center'};
    .heading {
      font-size: 1.25rem;
      color: ${headingColor || colors.white};
      @media (${breakpoints.tablet}) {
        ${fullWidth &&
        css`
          font-size: 1.65rem;
        `}
      }
    }
    .body {
      font-size: 0.9rem;
      color: ${bodyColor || colors.white};
      @media (${breakpoints.tablet}) {
        ${fullWidth &&
        css`
          font-size: 2vw;
          width: 95%;
          @media (${breakpoints.laptop}) {
            font-size: 1.4rem;
          }
        `}
      }
    }
    .link-grid {
      font-size: 1.4rem;
      @media (${breakpoints.tablet}) {
        ${fullWidth &&
        css`
          font-size: 2vw;
          @media (${breakpoints.laptop}) {
            font-size: 1.05rem;
          }
        `}
      }
      a {
        text-decoration: none;
        color: ${urlColor || colors.white};
      }
    }
    .bar-grid {
      height: 3px;
      background: ${colorBar};
      @media (${breakpoints.tablet}) {
        ${fullWidth &&
        css`
          height: 5px;
        `}
      }
    }
    .caption-grid {
      font-size: 1rem;
      @media (${breakpoints.tablet}) {
        ${fullWidth &&
        css`
          font-size: 2vw;
          @media (${breakpoints.laptop}) {
            font-size: 0.85rem;
          }
        `}
      }
    }
  `
})

const Div = styled.div(props => {
  const { fullWidth } = props

  return css`
    ${!fullWidth &&
    css`
      max-width: ${sizes.constrainWidth};
      margin: auto;
      padding: 0 3rem;
    `}
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .block {
      display: block;
      @media (${breakpoints.tablet}) {
        display: flex;
      }
    }
    .image {
      width: 250px;
      height: 250px;
      ${fullWidth &&
      css`
        width: 100vw;
        height: 100vw;
        @media (${breakpoints.tablet}) {
          width: 50vw;
          height: 50vw;
        }
      `}
    }
  `
})
