import React from 'react'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import SectionedContent from '../sectioned-content'

const query = graphql`
  query shareholderLettersQuery {
    sanityShareholderLetters {
      letters {
        title
        showTitle
        sectionedContent: _rawSectionedContent
      }
    }
  }
`

export default function ShareholderLetters() {
  const queryData = useStaticQuery(query)
  const page = {
    autoPopulate: true,
    centered: false,
    content: queryData?.sanityShareholderLetters?.letters.sort((a, b) => {
      return b.title - a.title
    }),
    expandedContent: false,
    heading: 'Shareholder Letters',
    navigation: false
  }

  return <SectionedContent css={styles} page={page} />
}

const styles = css`
  padding: 0 6rem;
`
