import React, { useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import CookieConsent from 'react-cookie-consent'
import globalStyles from '../styles'
import { colors } from '../styles/variables'
import { sizes, breakpoints } from '../styles/variables'
import Header from './header'
import Footer from './footer'
import useScrollBounce from '../hooks/use-scoll-bounce'

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allSanitySiteSettings {
      nodes {
        alertText
        alertLink
        alertActive
        linksOn
        site
        facebook
        twitter
        instagram
        youtube
        pinterest
        linkedin
        headerLogo {
          asset: _rawAsset
        }
        footerLogo {
          asset: _rawAsset
        }
        navigation {
          text
          url
          offset
          superscript
          hide
          subLinks {
            text
            url
            superscript
            newTab
            externalLink
            externalMessage
            innerLinks {
              text
              url
              superscript
            }
          }
        }
      }
    }
  }
`

export default function Layout({ children }) {
  const queryData = useStaticQuery(query)
  const { alertActive } = queryData?.allSanitySiteSettings?.nodes[0]

  useScrollBounce()

  useEffect(() => {
    const script = document.createElement(`script`)
    script.src = `https://cdn.userway.org/widget.js`
    script['data-account'] = process.env.GATSBY_USER_WAY_DATA_ACCOUNT
    document.head.appendChild(script)

    return () => {
      script.parentNode.removeChild(script)
    }
  })

  return (
    <>
      <Global styles={globalStyles} />
      <Header
        siteTitle={queryData.site.siteMetadata?.title || `Title`}
        queryData={queryData}
      />
      <Div alert={alertActive}>
        <main>{children}</main>
        <Footer queryData={queryData} />
      </Div>
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        cookieName="escaladeIncConsent"
        cookieValue={true}
        style={{
          background: colors.secondaryLight,
          color: colors.primary,
          fontSize: '0.75rem'
        }}
        buttonStyle={{
          background: colors.secondary,
          color: colors.white,
          fontSize: '0.75rem'
        }}
        expires={30}
      >
        <p className="consent">
          This website uses cookies to enhance the user experience.&emsp;By
          clicking "I Agree" you are agreeing to our{' '}
          <span>
            <Link to="/terms-of-use">Terms of Use</Link>
          </span>{' '}
          &{' '}
          <span>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </span>
          .
        </p>
      </CookieConsent>
    </>
  )
}

const Div = styled.div(props => {
  const { alertActive } = props

  const headerHeight = alertActive
    ? `calc(${sizes.headerHeight} + 2rem)`
    : sizes.headerHeight
  const headerHeightMobile = alertActive
    ? `calc(${sizes.headerHeightMobile} + 2rem)`
    : sizes.headerHeightMobile

  return css`
    min-height: calc(100vh - ${headerHeightMobile});
    margin-top: ${headerHeightMobile};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    main {
      flex: 1;
      width: 100%;
      margin: 0 auto;
      // ${!alertActive && `margin-top: 2rem;`}
    }

    @media (${breakpoints.laptop}) {
      min-height: calc(100vh - ${headerHeight});
      margin-top: ${headerHeight};
    }
  `
})
