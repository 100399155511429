import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Logo from './logo'
import { colors, sizes, padding, breakpoints, fonts } from '../styles/variables'
import Search from './search'
import DesktopMenu from './desktop-menu'
import MobileMenu from './mobile-menu'
import Alert from './alert'
import Ticker from './stock-ticker-display'
import config from '../../../config'

export default function Header({ queryData }) {
  const {
    navigation,
    headerLogo,
    alertText,
    alertLink,
    alertActive
  } = queryData?.allSanitySiteSettings?.nodes[0]

  const fluid = getFluidGatsbyImage(
    headerLogo?.asset?._ref,
    { maxWidth: 1000 },
    config
  )

  return (
    <Head alertActive={alertActive}>
      {alertActive && (
        <Alert className="alert" text={alertText} link={alertLink} />
      )}
      <Ticker />
      <div className="header-content">
        <Logo
          className="header-logo"
          image={fluid?.src}
          altText="Escalade Inc."
        />
        <MobileMenu navigation={navigation} />
        <div className="desktop-menu">
          <DesktopMenu navigation={navigation} />
        </div>
        <Search alertActive={alertActive} />
      </div>
    </Head>
  )
}

const Head = styled.header(props => {
  return css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: ${sizes.headerHeightMobile};
    background: ${colors.white};
    z-index: 2;
    .header-content {
      width: 100%;
      max-width: ${sizes.constrainWidth};
      height: calc(
        ${props.alertActive
          ? `${sizes.headerHeight} - 2rem`
          : `${sizes.headerHeight}`}
      );
      margin: 0 auto;
      padding: 0.5rem;
      @media (${breakpoints.laptop}) {
        padding: 1.5rem;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    a {
      text-decoration: none;
      color: ${colors.secondary};
    }

    .header-logo {
      display: flex;
      align-items: center;
      margin-top: -10px;

      img {
        height: 6rem;
      }
    }

    .desktop-menu {
      display: none;
    }

    nav {
      display: none;
      flex: 1;
      justify-content: space-between;
      font-size: 1.25vw;
      @media (min-width: 1250px) {
        font-size: 0.87rem;
      }
      font-family: ${fonts.primary};
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;

      a,
      span {
        :not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }

    .ticker {
      display: none;
    }

    .alert {
      text-align: center;
    }

    @media (${breakpoints.laptop}) {
      height: ${sizes.headerHeight};

      .header-content {
        height: calc(
          ${props.alertActive
            ? `${sizes.headerHeight} - 4rem`
            : `${sizes.headerHeight}`}
        );
        padding: ${padding.gutters};
        align-items: center;
        justify-content: flex-start;
      }

      .header-logo {
        display: block;
        flex: 1;
        max-width: 12rem;

        img {
          // height: unset;
        }
      }

      .desktop-menu {
        display: flex;
      }

      nav {
        display: flex;
      }
    }
  `
})
