import React, { useState } from 'react'
import { Link } from 'gatsby'
import { IoIosArrowForward as ForwardIcon } from 'react-icons/io'
import usePrevious from '../../../hooks/use-previous'
import { SlidingPanels, MenuItem } from '../styled'
import SubMenuPanel from './sub-menu'

export default function MobileMenuNavigation(props) {
  const [subMenuText, setSubMenuText] = useState(``)
  const { navigation } = props
  const activeSubMenu = navigation.find(n => n?.text === subMenuText)

  const prevSubMenu = usePrevious(activeSubMenu)
  const subMenu = activeSubMenu || prevSubMenu

  return (
    <SlidingPanels isMoved={!!activeSubMenu}>
      <section>
        {navigation.map((navItem, i) => {
          const { url, text, subLinks } = navItem || {}
          const key = `mobile-link-${i}`

          const isParent = Boolean(subLinks?.length)
          const isLinkTag = !isParent && url

          const itemProps = {
            as: isLinkTag ? Link : `div`,
            ...(isLinkTag && { to: url }),
            ...(isParent && {
              onClick: () => setSubMenuText(text)
            })
          }

          return (
            <>
              <MenuItem key={key} {...itemProps}>
                {text}
                {isParent && <ForwardIcon />}
              </MenuItem>
            </>
          )
        })}
      </section>
      <SubMenuPanel parentLink={subMenu} onBack={() => setSubMenuText(``)} />
    </SlidingPanels>
  )
}
