import { css } from '@emotion/core'
import { colors, fonts, breakpoints } from '../../styles/variables'

export default function getStyles(props) {
  const {
    align,
    secondary,
    light,
    outlined,
    hoverTextColor,
    fontSize,
    noFlex
  } = props

  let textColor = colors.primary
  let outlineColor = colors.primary

  if (secondary) {
    textColor = colors.secondary
    outlineColor = colors.secondary
  }

  if (light) {
    textColor = colors.white
    outlineColor = colors.secondaryLight
  }

  const outlinedCss = css`
    border: 1px solid ${outlineColor};

    &:hover {
      color: ${hoverTextColor || (light ? colors.black : colors.white)};
      background-color: ${outlineColor};
    }
  `

  const hoverCss = css`
    &:hover {
      color: ${hoverTextColor || textColor};
      opacity: ${hoverTextColor ? `1` : `0.6`};
    }
  `

  return css`
    ${!noFlex &&
    `display: flex;
    justify-content: ${align || 'left'};
    .tag {`}
    text-align: center;
    // padding: 0.5rem;
    text-decoration: none !important;
    font-family: ${fonts.secondary};
    font-size: ${fontSize || '0.625'}rem !important;
    font-weight: bold;
    color: ${textColor};
    transition: 0.2s;
    cursor: pointer !important;

    ${outlined ? outlinedCss : hoverCss}

    @media(${breakpoints.laptop}) {
      font-size: 0.8rem;
    }
    ${!noFlex && `}`}
  `
}
