import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import moment from 'moment-timezone'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'
import FileEntry from './file-entry'
import { colors, breakpoints, sizes } from '../../styles/variables'

const query = graphql`
  query annualReportsQuery {
    sanityAnnualReports {
      reports {
        reportTitle
        date
        file {
          file: _rawFile
          title
        }
        image: _rawImage
      }
    }
  }
`

export default function AnnualReports() {
  const queryData = useStaticQuery(query)
  const { reports } = queryData?.sanityAnnualReports
  const current = reports[reports.length - 1]
  const files = reports
    .filter(report => {
      return report !== reports[reports.length - 1]
    })
    .sort((a, b) => {
      return moment(b?.date).toDate() - moment(a?.date).toDate()
    })
    .map(report => {
      return {
        title:
          report?.reportTitle || moment(report?.date).format('MMMM DD, YYYY'),
        file: report?.file?.file
      }
    })

  const fluid = getFluidGatsbyImage(
    current?.image?.asset?._ref || current?.image?.asset?.id,
    { maxWidth: 1000, quality: 100 },
    config
  )

  const [dim, setDim] = useState({ dimension: '', size: '' })

  useEffect(() => {
    let img = new Image()
    img.src = fluid?.src
    img.onload = () => {
      if (img.height > img.width) {
        setDim({ dimension: 'h', size: '30' })
      } else if (img.width > img.height) {
        setDim({ dimension: 'w', size: '20' })
      } else {
        setDim({ dimension: 's', size: '25' })
      }
    }
  }, [])

  function getDim() {
    switch (dim.dimension) {
      case 'h':
        return `
                    height: ${dim.size * 1.25}vw;
                    width: auto;
                    @media(${breakpoints.tablet}) {
                        height: ${dim.size}vh;
                    }
                `
      case 'w':
        return `
                    width: ${dim.size * 2}vw;
                    height: auto;
                    @media(${breakpoints.tablet}) {
                        width: ${dim.size}vw;
                    }
                `
      case 's':
        return `
                    width: ${dim.size * 2}vw;
                    height: auto;
                    @media(${breakpoints.tablet}) {
                        width: ${dim.size}vw;
                    }
                `
      default:
        return `width: 0;`
    }
  }

  return (
    <Div>
      <div className="current">
        {/* <h2>Report</h2> */}
        <h2 />
        <FileEntry
          node={{
            title:
              current?.reportTitle ||
              moment(current?.date).format('MMMM DD, YYYY'),
            file: current?.file?.file
          }}
        />
        <img
          css={css`
            ${getDim()}
          `}
          src={fluid?.src}
        />
      </div>
      {files.length !== 0 && (
        <div className="past">
          <h2>Previous Reports</h2>
          {files.map(file => {
            return (
              <FileEntry
                className="older"
                node={{ title: file?.title, file: file.file }}
              />
            )
          })}
        </div>
      )}
    </Div>
  )
}

const Div = styled.div`
    padding: 0 1rem;
    @media(${breakpoints.phoneLarge}) {
        max-width: ${sizes.constrainWidth};
        padding 0 3rem;
        margin: auto;
    }
    display: flex;
    flex-wrap: wrap;
    h2 {
        color: ${colors.primary};
        font-weight: light;
    }
    .current {
        @media(${breakpoints.phoneLarge}) {
            margin-right: 15rem;
        }
        div {
            margin-bottom: 1rem;
        }
        img {
            border: 1px solid ${colors.primary};
        }
    }
    .past {
        height: auto;
        overflow-y: auto;
        margin-bottom: 2rem;
    }
    @media(${breakpoints.tablet}) {
        .past {
            height: 390px;
            overflow-y: auto;
        }
    }
`
