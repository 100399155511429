import React from 'react'
import { Bar } from 'react-chartjs-2'

export default function BarGraph(props) {
  const {
    datasets,
    labels,
    width,
    height,
    stacked,
    legend,
    hideLegend,
    title
  } = props?.graph
  const data = {
    labels,
    datasets: datasets.map(dataset => {
      return {
        label: dataset.label,
        borderWidth: dataset.borderWidth,
        data: dataset.data.map(data => {
          return data?.data
        }),
        backgroundColor: dataset.data.map(data => {
          return data?.backgroundColor
        }),
        borderColor: dataset.data.map(data => {
          return data?.borderColor
        }),
        hoverBackgroundColor: dataset.data.map(data => {
          return data?.hoverBackgroundColor
        }),
        hoverBorderColor: dataset.data.map(data => {
          return data?.hoverBorderColor
        })
      }
    })
  }
  const options = {
    title: {
      display: true,
      text: title,
      fontSize: 20
    },
    legend: {
      display: !hideLegend,
      position: legend
    },
    scales: {
      yAxes: [
        {
          stacked: stacked,
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          stacked: stacked,
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    maintainAspectRatio: false
  }

  return <Bar data={data} width={width} height={height} options={options} />
}
