import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { fonts, breakpoints } from '../styles/variables'

export default function TabbledContent({ page }) {
  const {
    heading,
    showHeading,
    table,
    empty,
    anchor,
    contentAlign,
    headingAlign,
    leftColumnBold,
    leftColumnAlign,
    headerColor,
    contentColor,
    borderColor,
    backgroundColor
  } = page

  const colors = table.map((element, index) => {
    return {
      id: `${heading
        .replace(/\s+/g, '-')
        .replace(/&+/g, 'and')
        .replace(/,+/g, '')
        .replace(/%+/g, '')
        .toLowerCase()}-${index}-color`,
      color: element?.colorOverride
        ? element?.colorOverride
        : element?.headerRow
        ? headerColor
        : contentColor
    }
  })

  return (
    <Div
      id={anchor}
      align={contentAlign}
      headAlign={headingAlign}
      left={leftColumnBold}
      leftAlign={leftColumnAlign}
      header={headerColor}
      content={contentColor}
      border={borderColor}
      background={backgroundColor}
      colors={colors}
    >
      {showHeading && (
        <div className="title">
          <h1>{heading}</h1>
        </div>
      )}
      <table>
        {table.map((element, index) => {
          const { row, headerRow, colspan, leftShift } = element

          if (headerRow) {
            return (
              <thead key={`${index}-head`}>
                <tr key={`${index}-row`} id={colors[index]?.id}>
                  {row.map((column, subIndex) => {
                    return (
                      <th
                        key={`${index}-${subIndex}-cell`}
                        className={`
                          ${subIndex === 0 ? `left` : ``}
                          ${subIndex === row.length - 1 ? `right` : ``}
                          ${
                            subIndex > 0 && subIndex < row.length - 1
                              ? `other`
                              : ``
                          }
                          ${index === table.length - 1 ? `bottom` : ``}
                          ${subIndex === 0 && leftShift ? `shift` : ``}
                      `}
                        colSpan={colspan ? colspan[subIndex] : 1}
                      >
                        {column ? column : empty}
                      </th>
                    )
                  })}
                </tr>
              </thead>
            )
          } else {
            return (
              <tbody key={`${index}-body`}>
                <tr key={`${index}-row`} id={colors[index]?.id}>
                  {row.map((column, subIndex) => {
                    return (
                      <td
                        key={`${index}-${subIndex}-cell`}
                        className={`
                          ${subIndex === 0 ? `left` : ``}
                          ${subIndex === row.length - 1 ? `right` : ``}
                          ${
                            subIndex > 0 && subIndex < row.length - 1
                              ? `other`
                              : ``
                          }
                          ${index === table.length - 1 ? `bottom` : ``}
                          ${subIndex === 0 && leftShift ? `shift` : ``}
                      `}
                        colSpan={colspan ? colspan[subIndex] : 1}
                      >
                        {leftColumnBold && subIndex === 0 && !leftShift ? (
                          <b>{column ? column : empty}</b>
                        ) : column ? (
                          column
                        ) : (
                          empty
                        )}
                      </td>
                    )
                  })}
                </tr>
              </tbody>
            )
          }
        })}
      </table>
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    .title {
      margin-bottom: 1rem;
      font-style: ${fonts.primary};
      font-weight: bolder;
      font-size: 1rem;
      color: black;
      text-align: center;

      @media (${breakpoints.laptop}) {
        text-align: left;
      }
    }
    table {
      border-collapse: collapse;
      background: ${props.background || 'transparent'};
      width: 90vw;
      thead {
        tr {
          th {
            ${props.border && `border-bottom: 2px solid ${props.border};`}
            text-align: ${props.headAlign || props.align || 'center'};
            width: 100%;
            padding: 10px;
          }
        }
      }
      tbody {
        tr {
          td {
            ${props.border && `border-bottom: 2px solid ${props.border};`}
            text-align: ${props.align || 'center'};
            width: 100%;
            padding: 10px;
          }
        }
      }
      @media (${breakpoints.laptop}) {
        width: 100%;
      }
    }
    .left {
      text-align: ${props.leftAlign || 'center'};
      padding: 10px 50px;
    }
    .right {
      padding-right: 50px;
    }
    .other {
      ${props.border && `border-right: 2px solid ${props.border};`}
    }
    .bottom {
      border-bottom: none;
    }
    .shift {
      padding-left: 75px;
    }
    ${props?.colors.map(color => {
      return `#${color?.id}{color: ${color.color};}`
    })}
  `
})
