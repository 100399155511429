import { css } from '@emotion/core'
import { colors, breakpoints } from '../../../../styles/variables'

const hoverStateStyles = css`
  .hover {
    opacity: 1;
  }

  .overlay {
    opacity: 0.25;
  }
`

const nonHoverStateStyles = css`
  .hover {
    opacity: 0;
  }

  .overlay {
    opacity: 0;
  }
`

export default css`
  height: 527px;
  width: 366px;
  position: relative;
  background: none;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 1.25rem;
  color: ${colors.white};

  &,
  * {
    transition: all 0.4s;
  }

  .background-image {
    width: 366px;
    height: 527px;
    margin: auto;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 366px;
    height: 527px;
    z-index: 0;
    background: black;
  }

  ${hoverStateStyles}

  @media(${breakpoints.laptop}) {
    ${nonHoverStateStyles}

    &:hover {
      ${hoverStateStyles}
    }
  }
`
