import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { sizes, colors, breakpoints, fonts } from '../../styles/variables'

const query = graphql`
  query alertTooltips {
    sanitySiteSettings {
      email {
        id
        text
      }
    }
  }
`

export default function EmailSignup() {
  const [all, setAll] = useState(false)
  const [feedback, setFeedback] = useState({})
  const [subButton, setSubButton] = useState(false)
  const [email, setEmail] = useState('')
  const [selections, setSelections] = useState({
    stock: false,
    sec: false,
    quarter: false,
    annual: false,
    current: false,
    events: false,
    presentations: false,
    reports: false,
    letters: false,
    news: false
  })

  const queryData = useStaticQuery(query)
  const tooltips = queryData.sanitySiteSettings.email

  useEffect(() => {
    if (
      selections.stock &&
      selections.sec &&
      selections.quarter &&
      selections.annual &&
      selections.current &&
      selections.events &&
      selections.presentations &&
      selections.reports &&
      selections.letters &&
      selections.news
    ) {
      setAll(true)
    } else {
      setAll(false)
    }
  }, [selections])

  // useEffect(() => {
  //     handleAll()
  // }, [all])

  // const handleAll = () => {
  //     setSelections({
  //         stock: all,
  //         sec: all,
  //         quarter: all,
  //         annual: all,
  //         current: all,
  //         events: all,
  //         presentations: all,
  //         reports: all,
  //         letters: all,
  //         news: all
  //     })
  // }

  const handleState = e => {
    const { name } = e.target

    setSelections(prevState => {
      return {
        ...prevState,
        [name]: !selections[name]
      }
    })
  }

  function getSubscribedSelections(items) {
    let str = ''
    Object.keys(items).map(item => {
      str = str.concat(`&${item}=${selections[item]}`)
    })
    return str
  }

  async function subscribe() {
    if (email) {
      const response = await fetch(
        `/.netlify/functions/subscribe?&email=${email}${getSubscribedSelections(
          selections
        )}`
      )
      const json = await response.json()
      setFeedback({ valid: json.valid, message: json.message })
    } else {
      setFeedback({ valid: false, message: 'Please enter your email address' })
    }
  }

  async function preferences() {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      const response = await fetch(
        `/.netlify/functions/preferences?&email=${email}`
      )
      const json = await response.json()
      setFeedback({ valid: json.valid, message: json.message })
      setSubButton(json.valid)
      if (json.valid) {
        setSelections({
          stock: json.stock,
          sec: json.sec,
          quarter: json.quarter,
          annual: json.annual,
          current: json.current,
          events: json.events,
          presentations: json.presentations,
          reports: json.reports,
          letters: json.letters,
          news: json.news
        })

        if (
          json.stock &&
          json.sec &&
          json.quarter &&
          json.annual &&
          json.current &&
          json.events &&
          json.presentations &&
          json.reports &&
          json.letters &&
          json.news
        ) {
          setAll(true)
        }
      } else {
        setSelections({
          stock: false,
          sec: false,
          quarter: false,
          annual: false,
          current: false,
          events: false,
          presentations: false,
          reports: false,
          letters: false,
          news: false
        })
      }
    }
  }

  async function unsubscribe() {
    if (email) {
      const response = await fetch(
        `/.netlify/functions/unsubscribe?&email=${email}`
      )
      const json = await response.json()
      setFeedback({ valid: json.valid, message: json.message })
    } else {
      setFeedback({ valid: false, message: 'Please enter your email address' })
    }
  }

  return (
    <div css={styles}>
      <Div feedback={feedback}>
        <h1>INVESTOR ALERTS</h1>
        <h4>Sign up for Email Alerts from Escalade Inc.</h4>
        <input
          className="email"
          type="email"
          placeholder="your_email@email.com"
          onBlur={preferences}
          onChange={e => {
            setEmail(e.target.value)
          }}
        />
        <sup className="info" data-tip data-for="input">
          <AiOutlineInfoCircle />
        </sup>
        <div className="checks">
          <div className="left">
            <div>
              <input
                type="checkbox"
                id="stock"
                name="stock"
                checked={selections.stock}
                onChange={handleState}
              />
              <label for="stock">
                Daily Stock Quote
                <sup data-tip data-for={tooltips[0].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="sec"
                name="sec"
                checked={selections.sec}
                onChange={handleState}
              />
              <label for="sec">
                All SEC Filings
                <sup data-tip data-for={tooltips[1].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="quarter"
                name="quarter"
                checked={selections.quarter}
                onChange={handleState}
              />
              <label for="quarter">
                Quarterley SEC Filings
                <sup data-tip data-for={tooltips[2].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="annual"
                name="annual"
                checked={selections.annual}
                onChange={handleState}
              />
              <label for="annual">
                Annual SEC Filings
                <sup data-tip data-for={tooltips[3].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="current"
                name="current"
                checked={selections.current}
                onChange={handleState}
              />
              <label for="current">
                Current SEC Filings
                <sup data-tip data-for={tooltips[4].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
          </div>
          <div className="right">
            <div>
              <input
                type="checkbox"
                id="events"
                name="events"
                checked={selections.events}
                onChange={handleState}
              />
              <label for="events">
                Escalade Inc Events
                <sup data-tip data-for={tooltips[5].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="presentations"
                name="presentations"
                checked={selections.presentations}
                onChange={handleState}
              />
              <label for="presentations">
                Shareholder Presentations
                <sup data-tip data-for={tooltips[6].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="reports"
                name="reports"
                checked={selections.reports}
                onChange={handleState}
              />
              <label for="reports">
                Annual Reports
                <sup data-tip data-for={tooltips[7].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="letters"
                name="letters"
                checked={selections.letters}
                onChange={handleState}
              />
              <label for="letters">
                Annual Shareholder Letters
                <sup data-tip data-for={tooltips[8].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
            <div>
              <input
                type="checkbox"
                id="news"
                name="news"
                checked={selections.news}
                onChange={handleState}
              />
              <label for="news">
                Company News
                <sup data-tip data-for={tooltips[9].id}>
                  <AiOutlineInfoCircle />
                </sup>
              </label>
            </div>
          </div>
        </div>
        <div className="buttons selector">
          <button
            className="all"
            onClick={() => {
              setAll(!all)
              setSelections({
                stock: !all,
                sec: !all,
                quarter: !all,
                annual: !all,
                current: !all,
                events: !all,
                presentations: !all,
                reports: !all,
                letters: !all,
                news: !all
              })
            }}
          >
            <div>{!all ? 'Select All' : 'Deselect All'}</div>
          </button>
        </div>
        <div className="buttons">
          <button className="subscribe" onClick={subscribe}>
            <div>{subButton ? 'Update' : 'Subscribe'}</div>
          </button>
          {/* <button className='preferences' onClick={preferences}><div>Preferences</div></button> */}
          <button className="unsubscribe" onClick={unsubscribe}>
            <div>Unsubscribe</div>
          </button>
        </div>
        <div className="feedback">
          <p>{feedback?.message}</p>
        </div>
      </Div>
      <ReactTooltip
        id="input"
        className="tooltip"
        backgroundColor={colors.secondary}
        arrowColor="transparent"
        delayShow={150}
        delayHide={400}
        effect="solid"
        place="right"
        getContent={() => {
          return (
            <Info>
              <div>
                <p>
                  You can select different email preferences by clicking each
                  option's individual checkbox.
                </p>
                <p>
                  Each option also has an information bubble on it's right
                  explaining what it does.
                </p>
              </div>
              <hr />
              <div>
                <p>
                  When you've selected all of your desired options click the
                  Subscribe button.
                </p>
                <p>
                  You can also click the Select All button to select every
                  option. This same button will allow you to deselect all if you
                  have selected all options.
                </p>
              </div>
              <hr />
              <div>
                <p>
                  You can also unsubscribe on this page, simply enter your email
                  and click the Unsubscribe button.
                </p>
              </div>
              <hr />
              <div>
                <p>
                  If you are already subscribed simply enter your email and
                  click out of the text box to view your alert preferences.
                </p>
              </div>
            </Info>
          )
        }}
      />
      {tooltips.map(tooltip => {
        const { id, text } = tooltip

        return (
          <ReactTooltip
            id={id}
            className="tooltip"
            backgroundColor={colors.secondary}
            arrowColor="transparent"
            delayShow={150}
            delayHide={400}
            effect="solid"
            place="right"
            getContent={() => {
              return (
                <Info>
                  <div>
                    <p>{text}</p>
                  </div>
                </Info>
              )
            }}
          />
        )
      })}
    </div>
  )
}

const styles = css`
    h1,
    h2,
    h4 {
      @media(${breakpoints.phoneLarge}) {
        line-height: 150%;
    }
    .tooltip {
        width 40vw;
        @media(${breakpoints.laptop}) {
            width: 50vw;
        }
    }
`

const Info = styled.div(props => {
  return css`
    div {
      line-height: 50%;
    }
    p {
      line-height: 100%;
    }
    hr {
      visibility: hidden;
    }
  `
})

const Div = styled.div(props => {
  const { valid, message } = props?.feedback

  return css`
        padding: 0 0.5rem;
        @media(${breakpoints.phoneLarge}) {
            max-width: ${sizes.constrainWidth};
            padding: 0 3rem;
            margin: auto;
        }
        h1 {
            font-family: ${fonts.primary};
            font-weight: bold;
            color: ${colors.primary};
        }
        h4 {
            font-family: ${fonts.secondary};
        }
        .email {
            width: 20rem;
            height: 35px;
            padding: 10px;
            font-size: 1.1rem;
            font-family: ${fonts.secondary};
            margin-bottom: 1rem;
        }
        .info {
            width: 20rem;
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }
        .checks {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            label {
                padding-left: 5px;
            }
            label > sup {
                font-size: 0.6rem;
            }
            .all {
                border 2px solid ${colors.secondary};
                background: transparent;
                border-radius: 5px;
                cursor: pointer;
                width: 35%;
                transition: 0.4s;
                div {
                    color: ${colors.primary};
                    padding: 2px 0;
                    font-size: 0.65rem;
                    font-weight: 700;
                    font-family: ${fonts.secondary};
                    transition: 0.4s;
                }
                :hover {
                    background: ${colors.secondaryLight};
                    div {
                        color: ${colors.secondary};
                    }
                }
            }
            @media(min-width: 586px) {
                .left {
                    padding: 10px 0 10px;
                }
                .right {
                    padding: 10px 10px 0;
                }
            }
        }
        .buttons {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            margin-bottom: 1rem;
            button {
                border 2px solid ${colors.secondary};
                background: transparent;
                border-radius: 5px;
                cursor: pointer;
                transition: 0.4s;
                div {
                    color: ${colors.primary};
                    padding: 5px 0;
                    font-size: 1rem;
                    font-weight: 700;
                    font-family: ${fonts.primary};
                    transition: 0.4s;
                }
                :hover {
                    background: ${colors.secondaryLight};
                    div {
                        color: ${colors.secondary};
                    }
                }
            }
        }
        .selector {
            button > div {
                font-size: 0.55rem;
                width: 3.5rem;
            }
        }
        .feedback {
            ${!message && 'visibility: hidden;'}
            color: ${valid ? colors.secondary : colors.red};
        }
    `
})
