import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { Chrono } from 'react-chrono'
import BlockContent from './block-content'
import { breakpoints, colors, sizes } from '../styles/variables'

const query = graphql`
  query historyQuery {
    allSanityCompanyHistory {
      nodes {
        history {
          year
          cardTitle
          cardSubtitle
          historyContent: _rawHistoryContent(
            resolveReferences: { maxDepth: 10 }
          )
        }
      }
    }
  }
`

export default function TimelineContent() {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    window && setWidth(window.innerWidth)
  })

  const queryData = useStaticQuery(query)
  const nodes = queryData?.allSanityCompanyHistory?.nodes[0].history

  const items = nodes.map(node => {
    const { year, cardTitle, cardSubtitle } = node
    return {
      title: year,
      cardTitle: cardTitle || year,
      cardSubtitle: cardSubtitle
    }
  })

  const Timeline = styled(props => (
    <Chrono
      cardPositionHorizontal="BOTTOM"
      hideControls={width <= 1024 ? true : false}
      useReadMore={true}
      itemWidth={150}
      cardWidth={600}
      items={items}
      mode={
        width >= 1024
          ? 'HORIZONTAL'
          : width >= 768
          ? 'VERTICAL_ALTERNATING'
          : 'VERTICAL'
      }
      theme={{
        primary: colors.primary,
        secondary: colors.primaryLight,
        titleColor: colors.secondaryLight,
        textColor: colors.secondary,
        cardBgColor: colors.white,
        cardForeColor: colors.secondary
      }}
      {...props}
    >
      {nodes.map((node, index) => {
        return (
          <BlockContent
            key={`timeline-${index}`}
            blocks={node?.historyContent}
          />
        )
      })}
    </Chrono>
  ))``

  return (
    <Div width={width}>
      <Timeline />
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    @media (${breakpoints.tablet}) {
      margin: auto;
      max-width: ${sizes.constrainWidth};
      padding: 0 3rem;
    }
    width: 100%;
    height: auto;
    .card-title {
      font-style: italic;
      font-weight: 900;
      color: ${colors.primary};
      font-size: 2rem;
      padding: 10px;
    }
    .card-sub-title {
      font-style: italic;
      font-weight: light;
      color: ${colors.primary};
      font-size: 1.25rem;
      padding: 10px;
    }
    .card-description: {
      padding: 10px;
    }
    .timeline-item-title {
      background: transparent !important;
      color: ${colors.secondary} !important;
    }
    .timeline-circle.active,
    .timeline-vertical-circle.active {
      background: transparent !important;
    }
    .timeline-controls {
      background: transparent !important;
    }
    @media (${breakpoints.laptop}) {
      .timeline-card-content {
        > header {
          p {
            padding: 0 0 10px 0 !important;
          }
        }
        .card-description {
          padding: 0.25em 0 0 0 !important;
          margin: 0 0 !important;
          width: 100% !important;
          > * {
            text-align: left !important;
          }
        }
      }
    }
    .timeline-horz-card-wrapper {
      width: 100% !important;
    }
    .timeline-main-wrapper > div > div {
      @media (${breakpoints.laptop}) {
        height: 4px;
      }
    }
    #react-chrono-timeline {
      width: 100% !important;
      div {
        section {
          filter: none !important;
          margin: auto;
          @media (${breakpoints.laptop}) {
            max-height: 500px;
          }
        }
      }
    }
    ${props.width < 768 &&
    `.timeline-item-title {
      font-size: 0.75rem !important;
    }`}
    ${props.width < 450 &&
    `.timeline-item-title {
      visibility: hidden;
    }`}
  `
})
