import React, { useState, useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Image from 'gatsby-image'
import { css } from '@emotion/core'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import BlockContent from './block-content'
import Modal from './modal'
import { colors, sizes, breakpoints } from '../styles/variables'
import config from '../../../config'

export default function MediaCard(props) {
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [fontSize, setFontSize] = useState(0)

  const {
    forceLeft,
    forceRight,
    i,
    url,
    externalMessage,
    title,
    subTitle,
    caption,
    body,
    link,
    linkText,
    linkStyle,
    alt,
    imageId,
    externalLink,
    anchor
  } = props
  useEffect(() => {
    if (document && props?.title) {
      setOffsetHeight(
        document.getElementById(
          `${title
            .replace(/[\W_]+/g, '-')
            .replace(/&+/g, 'and')
            .toLowerCase()}-${i}`
        )?.offsetHeight
      )
      setFontSize(
        Number(
          window
            .getComputedStyle(document.body)
            .getPropertyValue('font-size')
            .match(/\d+/)[0]
        )
      )
    }
  })

  const Tag = url ? AnchorLink : `div`
  const toProp = url ? { to: url } : {}

  const internal = /^\/(?!\/)/.test(link)

  const styles = getStyles(props, offsetHeight, fontSize)

  const fluid = getFluidGatsbyImage(imageId, { maxWidth: 800 }, config)

  const defautLinkStyle = link && (linkStyle==='bold'|| !linkStyle)
  const modernLinkStyle = link && (linkStyle==='modern')

  return !externalLink ? (
    forceLeft ? (
      <span css={styles} id={anchor}>
        <Tag {...toProp}>
          {fluid && (
            <Image
              className="media"
              alt={alt}
              fluid={fluid}
              objectFit="cover"
            />
          )}
        </Tag>
        {!fluid && <div>Image not available</div>}
        {body && <BlockContent className="body" blocks={body} />}
      </span>
    ) : forceRight ? (
      <span css={styles} id={anchor}>
        {body && <BlockContent className="body" blocks={body} />}
        <Tag {...toProp}>
          {fluid && (
            <Image
              className="media"
              alt={alt}
              fluid={fluid}
              objectFit="cover"
            />
          )}
        </Tag>
        {!fluid && <div>Image not available</div>}
      </span>
    ) : (
      <div css={styles} id={anchor}>
        <Tag {...toProp}>
          {fluid && (
            <Image
              className="media"
              alt={alt}
              fluid={fluid}
              objectFit="cover"
            />
          )}
        </Tag>
        {!fluid && <div>Image not available</div>}
        {title && (linkStyle===undefined) && (
          <div
            id={`${title
              .replace(/[\W_]+/g, '-')
              .replace(/&+/g, 'and')
              .toLowerCase()}-${i}`}
            className="title"
          >
            {title}
          </div>
        )}    
        {title && (linkStyle==='imageLink') && (
            <div
              id={`${title
                .replace(/[\W_]+/g, '-')
                .replace(/&+/g, 'and')
                .toLowerCase()}-${i}`}
                className="imageLinkTitle"
            >
              <AnchorLink to={link}>
                <b>{title}</b>
              </AnchorLink>
            </div>
        )}

        {subTitle && <div className="subTitle">{subTitle}</div>}
        {caption && <div className="caption">{caption}</div>}    
        {body && (linkStyle===undefined) && <BlockContent className="body" blocks={body} />}
        {body && (linkStyle==='modern') && <BlockContent className="modernBody" blocks={body} />}
        {defautLinkStyle && (
            <div className="lower-link">
              {internal ? (
                <AnchorLink to={link}>
                  <b>{linkText}</b>
                </AnchorLink>
              ) : (
                <Modal
                  to={link}
                  externalMessage={externalMessage}
                  cursor="pointer"
                  component={
                    <a>
                      <b>{linkText}</b>
                    </a>
                  }
                />
              )}
            </div>
          )}
          {modernLinkStyle && (
            <div className="modernLink">
                <AnchorLink to={link}>
                  <b>{linkText}</b>
                </AnchorLink>     
            </div>
          )}
      </div>
    )
  ) : (
    <Modal
      css={styles}
      {...toProp}
      externalMessage={externalMessage}
      component={
        <>
          {fluid && (
            <Image
              id={anchor}
              className="media"
              fluid={fluid}
              objectFit="cover"
            />
          )}
          {!fluid && <div className="media">Image not available</div>}
          {title && (
            <div
              id={`${title
                .replace(/[\W_]+/g, '-')
                .replace(/&+/g, 'and')
                .toLowerCase()}-${i}`}
              className="title"
            >
              {title}
            </div>
          )}
          {subTitle && <div className="subTitle">{subTitle}</div>}
          {caption && <div className="caption">{caption}</div>}
          {body && <BlockContent className="body" blocks={body} />}
          {defautLinkStyle &&  (
            <div className="lower-link">
              {internal ? (
                <AnchorLink to={link}>
                  <b>{linkText}</b>
                </AnchorLink>
              ) : (
                <Modal
                  to={link}
                  externalMessage={externalMessage}
                  cursor="pointer"
                  component={
                    <a>
                      <b>{linkText}</b>
                    </a>
                  }
                />
              )}
            </div>
          )}
          {modernLinkStyle && (
            <div className="modernLink">
              {internal ? (
                <AnchorLink to={link}>
                  <b>{linkText}</b>
                </AnchorLink>
              ) : (
                <Modal
                  to={link}
                  externalMessage={externalMessage}
                  cursor="pointer"
                  component={
                    <a>
                      <b>{linkText}</b>
                    </a>
                  }
                />
              )}
            </div>
          )}
        </>
      }
    />
  )
}

const getStyles = (props, offsetHeight, fontSize) => css`
  text-align: ${props?.align || 'center'};
  display: flex;
  align-items: center;
  ${!props?.forceLeft && !props?.forceRight && 'flex-flow: column nowrap;'}
  ${(props?.forceLeft || props?.forceRight) &&
  `
      display: block;
      @media(${breakpoints.tablet}) {
        display: flex;
        align-items: center;
      }
      padding: 0 ;
      margin: auto;
      @media(${breakpoints.phoneLarge}) {
        max-width: ${sizes.constrainWidth};
        padding: 0 3rem;
      }
    `}

  .media {
    height: ${props?.height ? props?.height : '275'}px;
    width: ${props?.width ? props?.width * 0.75 : '275'}px;
    border-radius: ${props?.radius ? props?.radius : '0'}%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.secondary};
    color: ${colors.secondaryLight};
    ${(props?.forceLeft || props?.forceRight) &&
    `
      max-width: ${sizes.constrainWidth};
      padding: 0 3rem;
      margin: auto;
    `}
    @media(${breakpoints.phoneLarge}) {
      height: ${props?.height ? props?.height : '275'}px;
      width: ${props?.width ? props?.width : '275'}px;
    }
  }

  .title,
  .caption,
  .subTitle,
  .body,
  .lower-link {
    padding: 0.5rem;
    line-height: 18px;
    user-select: none;
    width: ${props?.width ? props?.width * 0.75 : '275'}px;
    height: auto;
    ${(props?.forceLeft || props?.forceRight) &&
    `
      padding: 0 1rem;
      margin: auto;
      @media(${breakpoints.tablet}) {
        max-width: ${sizes.constrainWidth};
        padding: 0 3rem;
      }
    `}
    @media(${breakpoints.phoneLarge}) {
      width: ${props?.width ? props?.width : '275'}px;
    }
  }

  .title {
    font-weight: bolder;
    font-size: ${`${props?.titleSize}rem` || `15px`};
    color: ${props?.titleColor || `black`};
    background: ${props?.titleBackground || `white`};
    ${props?.forcedTitleHeight && `height: ${props?.forcedTitleHeight}px;`}
    ${props?.imageTitleOverlay &&
    `
      z-index: 1;
      margin-top: -${
        props?.forcedTitleHeight
          ? props?.forcedTitleHeight / fontSize
          : offsetHeight / fontSize
      }rem;
      background: ${
        props?.titleBackground ? `${props?.titleBackground}85` : `white`
      };
    `}
    ${(props?.forceLeft || props?.forceRight) &&
    `
      @media(${breakpoints.tablet}) {
        max-width: ${sizes.constrainWidth};
        padding: 0 3rem;
        margin: auto;
      }
    `}
  }

  .imageLinkTitle {
    font-size: ${`${props?.titleSize}rem`};
    ${props?.imageTitleOverlay &&
    `
      z-index: 1;
      margin-top: -${
        props?.forcedTitleHeight
          ? props?.forcedTitleHeight / fontSize
          : offsetHeight / fontSize
      }rem;
    `}

    a {
      color: white;
      cursor: pointer;      
      display:block;
      text-align:center;
      margin-top:10px;
      margin-bottom:0px;
      width:273px;
      height:34px;
      line-height: 34px;      
      /*Set opacity = 0.52 only to background, not text*/ 
      background: rgba(10, 76, 152, 0.52);
    }
  }

  .subTitle {
    font-weight: bolder;
    font-size: ${`${props?.subTitleSize}rem` || `15px`};
    color: ${props?.subTitleColor || `black`};
    background: ${props?.subTitleBackground || `white`};
    ${props?.forcedSubTitleHeight &&
    `height: ${props?.forcedSubTitleHeight}px;`}
    ${(props?.forceLeft || props?.forceRight) &&
    `
      @media(${breakpoints.tablet}) {
        max-width: ${sizes.constrainWidth};
        padding: 0 3rem;
        margin: auto;
      }
    `}
  }

  .caption {
    font-weight: bolder;
    font-size: ${`${props?.captionSize}rem` || `10px`};
    color: ${props?.captionColor || `black`};
    background: ${props?.captionBackground || `white`};
    ${props?.forcedCaptionHeight && `height: ${props?.forcedCaptionHeight}px;`}
    ${(props?.forceLeft || props?.forceRight) &&
    `
      @media(${breakpoints.tablet}) {
        max-width: ${sizes.constrainWidth};
        padding: 0 3rem;
        margin: auto;
      }
    `}
  }

  .body {
    font-size: 0.5rem;
    color: ${props?.bodyColor || `black`};
    background: ${props?.bodyBackground || `white`};
    ${props?.forcedBodyHeight && `height: ${props?.forcedBodyHeight}px;`}
    ${(props?.forceLeft || props?.forceRight) &&
    `
      @media(${breakpoints.tablet}) {
        max-width: ${sizes.constrainWidth};
        padding: 1.5rem 3rem;
        margin: auto;
      }
      width: ${props?.width ? props?.width * 0.75 : '275'}px;
      height: auto;
      p {
        margin: 0;
      }
      @media(${breakpoints.tablet}) {
        height: ${props?.height ? props?.height : '275'}px;
        // width: ${props?.width ? props?.width : '275'}px;
        width: 100%;
      }
    `}
    @media(${breakpoints.netbook}) {
      font-size: ${`${props?.bodySize}rem` || `12.5px`};
    }
  }

  .lower-link {
    background: ${props?.bodyBackground || `white`};
    a {
      color: ${props?.bodyColor || `black`};
      cursor: pointer;
    }
    transition: 0.4s;
    :hover {
      color: ${`${props?.bodyColor}80` || `white`};
    }
    ${props?.forcedTitleHeight && `height: ${props?.forcedTitleHeight}px;`}
    ${props?.imageTitleOverlay &&
    `
      z-index: 1;
      margin-top: -${
        props?.forcedTitleHeight
          ? props?.forcedTitleHeight / fontSize
          : offsetHeight / fontSize
      }rem;
      background: ${
        props?.bodyBackground ? `${props?.bodyBackground}85` : `white`
      };
    `}
  }
  .modernLink {
    background: ${props?.bodyBackground};
    a {
      color: ${colors.primaryLight};
      cursor: pointer;
      font-style: italic;
    }


    ${`width:  ${props?.width ? props?.width :'275'}px;`}
    padding-left: 25px;
    padding-bottom: 25px;
    font-size: 0.8rem;        
  }
  .modernBody {
    font-size: 0.7rem;
    color: ${props?.bodyColor};
    background: ${props?.bodyBackground};
    height: 420px;
    width: 275px;
    padding-left: 27px;
    padding-right: 27px;
    line-height: 1rem;

    /*Make font size bigger in the 1st,2nd paragraph in RECENT PARTNERSHIPS in partnership page */
    p:first-child {
      font-size: 0.9rem;             
    }
    p:nth-child(2) {
      font-size: 0.9rem;   
  }
`
