import styled from '@emotion/styled'
import { colors, fonts } from '../styles/variables'

const Card = styled.div`
  padding: 2rem 2.5rem;
  background-color: ${colors.secondary};
  color: ${colors.white};
  font-family: ${fonts.secondary};
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`

export default Card
