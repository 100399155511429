import React from 'react'
import styled from '@emotion/styled'
import { FaDownload } from 'react-icons/fa'
import { colors } from '../styles/variables'

export default function FileDisplay({ page, underlined }) {
  const { files, heading, showHeading, anchor } = page

  return (
    <Div id={anchor}>
      {showHeading && <h3>{heading}</h3>}
      {files.map(file => {
        return (
          <div key={file?.title}>
            <span>
              <b>
                <a href={`${file?.file?.asset?.url}#toolbar=0`} target="_blank">
                  {file?.title}
                  {underlined && <div />}
                </a>
              </b>
            </span>
            <span>
              <a
                href={`${file?.file?.asset?.url}?dl=${file?.file?.asset?.originalFilename}`}
              >
                <FaDownload />
              </a>
            </span>
          </div>
        )
      })}
    </Div>
  )
}

const Div = styled.div`
  display: block;

  h3 {
    color: ${colors.primary};
    font-size: 2rem;
    font-weight: light;
  }

  div {
    span {
      cursor: pointer;
      margin-right: 15px;
      a {
        color: black;
        text-decoration: none;
        font-weight: bold;

        :hover {
          color: ${colors.primary};
        }
      }

      div {
        visibility: hidden;
        height: 3px;
        width: 0;
        transition: 0.25s;
      }

      &:hover {
        color: ${colors.primary} !important;
        div {
          visibility: visible;
          width: 55px;
          background: ${colors.primary};
        }
      }
    }
  }
`
