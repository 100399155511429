import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { IoIosArrowBack as BackIcon } from 'react-icons/io'
import Modal from '../../modal'
import {
  SubMenuHeading,
  MenuItem,
  MenuItemInner,
  InnerItem,
  InnerBottom,
  Table
} from '../styled'

export default function SubMenu(props) {
  const { parentLink, onBack } = props
  const { url, text, superscript, subLinks } = parentLink || {}

  return (
    <section>
      <Table>
        <SubMenuHeading>
          <BackIcon onClick={onBack} />
          <strong>{text || `Menu`}</strong>
          {superscript && <sup>&nbsp;{superscript}</sup>}
        </SubMenuHeading>
        {url && (
          <MenuItem to={url} as={Link}>
            {text || `View All`}
          </MenuItem>
        )}
        {subLinks?.map?.((subLink, i) => {
          const {
            url,
            text,
            newTab,
            externalLink,
            externalMessage,
            innerLinks
          } = subLink || {}
          const key = `mobile-suburl-${i}`

          if (!url || !text) return null

          return !newTab && !externalLink ? (
            innerLinks.length !== 0 ? (
              <>
                <MenuItemInner key={key} to={url} as={Link}>
                  {text}
                  {subLink?.superscript && (
                    <>
                      &nbsp;<sup>{subLink.superscript}</sup>
                    </>
                  )}
                </MenuItemInner>
                {innerLinks.map((item, i) => {
                  return (
                    <InnerItem
                      key={`${item.text}-${i}-inner`}
                      to={item.url}
                      as={AnchorLink}
                    >
                      {item.text}
                      {item.superscript && (
                        <>
                          &nbsp;<sup>{item.superscript}</sup>
                        </>
                      )}
                    </InnerItem>
                  )
                })}
                <InnerBottom />
              </>
            ) : (
              <MenuItem key={key} to={url} as={Link}>
                {text}
                {subLink?.superscript && (
                  <>
                    &nbsp;<sup>{subLink.superscript}</sup>
                  </>
                )}
              </MenuItem>
            )
          ) : !externalLink ? (
            <MenuItem key={key} to={url} as={<a target="_blank" />}>
              {text}
              {subLink?.superscript && (
                <>
                  &nbsp;<sup>{subLink.superscript}</sup>
                </>
              )}
            </MenuItem>
          ) : (
            <Modal
              to={url}
              externalMessage={externalMessage}
              fontSize={1.1}
              cursor="pointer"
              component={
                <MenuItem key={key} to={url} as={<a />}>
                  {text}
                  {subLink?.superscript && (
                    <>
                      &nbsp;<sup>{subLink.superscript}</sup>
                    </>
                  )}
                </MenuItem>
              }
            />
          )
        })}
      </Table>
    </section>
  )
}
