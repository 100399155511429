import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import { CgPlayButtonO } from 'react-icons/cg'

export default function Video(props) {
  const { video, videoAlign, videoSize } = props?.node
  return (
    <Section align={videoAlign} size={videoSize}>
      <ReactPlayer
        url={video}
        style={videoStyles.videoPlayer}
        width="100%"
        height="100%"
        controls={true}
        playing={true}
        light={true}
        playIcon={
          <div className="play-button">
            <CgPlayButtonO />
          </div>
        }
        config={{
          youtube: {
            playerVars: { showinfo: 1 }
          }
        }}
      />
    </Section>
  )
}

const Section = styled.section(props => {
  return css`
    width: ${props?.size === 'large' ? `100%` : `50%`};
    height: 365px;
    display: flex;
    margin: ${props?.align === 'center' ? `0 auto` : `0`};
    text-align: center;
    .play-button {
      background: white;
      opacity: 0.75;
      border-radius: 100%;
      svg {
        display: flex;
        align-items: center;
        color: black;
        font-weight: bold;
        font-size: 5rem;
        cursor: pointer;
      }
    }
  `
})

const videoStyles = {
  videoPlayer: {
    margin: `0 auto`,
    frameborder: `0`
  }
}
