import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import Modal from '../../modal'
import Layout from '../../layout'
import { breakpoints, sizes } from '../../../styles/variables'

export default function StockGraph() {
  const handleLoad = () => {
    if (typeof window?.TradingView === `undefined`) return

    new window.TradingView.widget({
      width: '720px',
      height: '400px',
      symbol: 'NASDAQ:ESCA',
      timezone: 'Etc/UTC',
      theme: 'dark',
      style: '3',
      locale: 'en',
      toolbar_bg: '#f1f3f6',
      enable_publishing: false,
      hide_top_toolbar: true,
      hide_legend: false,
      withdateranges: true,
      range: '5D',
      save_image: false,
      container_id: 'tradingview_00fb1'
    })
  }

  useEffect(() => {
    const script = document.createElement(`script`)
    script.src = `https://s3.tradingview.com/tv.js`
    script.type = `text/javascript`
    script.charset = `utf-8`
    script.onload = handleLoad
    document.head.appendChild(script)

    return () => {
      script.parentNode.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <Div>
        <div className="tradingview-widget-container">
          <div id="tradingview_00fb1"></div>
          <Modal
            className="tradingview-widget-copyright"
            cursor="pointer"
            to="https://www.tradingview.com/symbols/NASDAQ-ESCA/"
            externalMessage="The information on this page is provided by TradingView, Inc., which entity is not affiliated with Escalade. Neither Escalade nor TradingView warrants the timeliness, accuracy, completeness, or usefulness of this information. You should not rely on this information in making any investment decision regarding Escalade’s securities. Any reliance you place on such information is strictly at your own risk. Escalade and TradingView disclaim all liability and responsibility for this information."
            component={
              <>
                <a rel="noopener" target="_blank">
                  <span className="blue-text">ESCA Chart</span>
                </a>{' '}
                by TradingView
              </>
            }
          />
        </div>
      </Div>
    </Layout>
  )
}

const Div = styled.div`
  @media (${breakpoints.phoneLarge}) {
    padding: 0 1rem;
    max-width: ${sizes.constrainWidth};
    margin: auto;
  }
  #tradingview_3dfef-wrapper {
    div {
      width: 50% !important;
    }
  }
  @media (${breakpoints.laptop}) {
    #tradingview_3dfef-wrapper {
      div {
        width: 100% !important;
      }
    }
  }
`
