import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  BarGraph,
  BubbleGraph,
  DoughnutGraph,
  HorizontalBarGraph,
  BarAndLineGraph,
  PieGraph,
  LineGraph,
  PolarGraph,
  RadarGraph,
  ScatterGraph,
  Error
} from './graph-types'
import { breakpoints } from '../../../styles/variables'

export default function Graph(props) {
  const [windowWidth, setWidth] = useState(0)

  useEffect(() => {
    window && setWidth(window.innerWidth)
  })

  function getGraph(graph) {
    try {
      switch (graph?._type) {
        case 'bar':
          return <BarGraph graph={graph} />
        case 'horizontalBar':
          return <HorizontalBarGraph graph={graph} />
        case 'line':
          return <LineGraph graph={graph} />
        case 'barAndLine':
          return <BarAndLineGraph graph={graph} />
        case 'scatter':
          return <ScatterGraph graph={graph} />
        case 'bubble':
          return <BubbleGraph graph={graph} />
        case 'pie':
          return <PieGraph graph={graph} />
        case 'doughnut':
          return <DoughnutGraph graph={graph} />
        case 'polar':
          return <PolarGraph graph={graph} />
        case 'radar':
          return <RadarGraph graph={graph} />
        default:
          return <Error graph={graph} />
      }
    } catch (e) {
      return <Error graph={graph} />
    }
  }

  return (
    <Div
      id={props?.node?.anchor}
      width={props?.node?.width}
      windowWidth={windowWidth}
    >
      {getGraph(props?.node?.graph[0])}
    </Div>
  )
}

const Div = styled.div(props => {
  const { width, windowWidth } = props
  return css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    @media (${breakpoints.phoneLarge}) {
      padding: 0 3rem;
    }
    margin: auto;
    width: 0;
    width: ${windowWidth > width + 100 || windowWidth === 0
      ? `${width}px`
      : 'auto'};
    height: auto;
  `
})
