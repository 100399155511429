import React, { useState } from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Popup from 'reactjs-popup'
import { BsSearch } from 'react-icons/bs'
import Modal from './modal'
import { colors, breakpoints } from '../styles/variables'

const query = graphql`
  query searchNavigationQuery {
    allSanitySiteSettings {
      nodes {
        navigation {
          text
          url
          superscript
          subLinks {
            text
            url
            superscript
            newTab
            externalLink
            externalMessage
            innerLinks {
              text
              url
              superscript
            }
          }
        }
      }
    }
  }
`

export default function Search({ alertActive }) {
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)

  const queryData = useStaticQuery(query)
  const { navigation } = queryData?.allSanitySiteSettings?.nodes[0]

  // Flattens the entire navigation structure into a single array and Filters it based on the input entry from the Modal Popup
  const flat = _.flatten([
    _.map(navigation, nav => {
      return {
        text: nav?.text,
        url: nav?.url,
        superscript: nav?.superscript,
        level: 1
      }
    }),
    _.flatten(
      _.map(navigation, nav => {
        return _.flatten(
          _.map(nav?.subLinks, nav2 => {
            return {
              text: nav2?.text,
              url: nav2?.url,
              superscript: nav2?.superscript,
              newtab: nav2?.newTab,
              externalLink: nav2?.externalLink,
              externalMessage: nav2?.externalMessage,
              level: 2
            }
          })
        )
      })
    ),
    _.flatten(
      _.map(navigation, nav => {
        return _.flatten(
          _.map(nav?.subLinks, nav2 => {
            return _.flatten(
              _.map(nav2?.innerLinks, nav3 => {
                return {
                  text: nav3?.text,
                  url: nav3?.url,
                  superscript: nav3?.superscript,
                  level: 3
                }
              })
            )
          })
        )
      })
    )
  ])
    .filter(element => {
      return element?.url
    })
    .filter(element => {
      return (
        new RegExp(
          '^' + `*${search.toLowerCase()}*`.replace(/\*/g, '.*') + '$'
        ).test(element?.text.toLowerCase()) || search === ''
      )
    })

  return (
    <Div alertActive={alertActive}>
      <button
        type="submit"
        onClick={() => {
          setOpen(true)
        }}
      >
        <BsSearch />
      </button>
      <Popup
        open={open}
        closeOnDocumentClick
        modal
        nested
        onClose={() => {
          setOpen(false)
        }}
      >
        <Panel>
          <input
            value={search}
            onChange={e => {
              setSearch(e.target.value)
            }}
          />
          <div>
            {search &&
              flat.map(item => {
                return !item?.newTab && !item?.externalLink ? (
                  <div>
                    <span>
                      <b>
                        <Link to={item?.url}>
                          {item?.text}
                          {item?.superscript && (
                            <sup>&nbsp;{item?.superscript}</sup>
                          )}
                        </Link>
                      </b>
                    </span>
                    &emsp;&emsp;
                    <span>{item?.url}</span>
                    <hr />
                  </div>
                ) : !item?.externalLink ? (
                  <div>
                    <span>
                      <b>
                        <a href={item?.url} target="_blank">
                          {item?.text}
                        </a>
                      </b>
                    </span>
                    &emsp;&emsp;
                    <span>{item?.url}</span>
                    <hr />
                  </div>
                ) : (
                  <Modal
                    to={item?.url}
                    externalMessage={item?.externalMessage}
                    component={
                      <>
                        <span>
                          <b>
                            <a style={{ cursor: 'pointer' }}>{item?.text}</a>
                          </b>
                        </span>
                        &emsp;&emsp;
                        <span style={{ 'font-size': '0.75rem' }}>
                          {item?.url}
                        </span>
                        <hr />
                      </>
                    }
                  />
                )
              })}
          </div>
        </Panel>
      </Popup>
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    position: absolute;
    ${!props.alertActive
      ? `
          top: 3px;
          left: 10px;
          button {
              font-size: 1.15rem;
          }
      `
      : `
        top: 15px;
        left: 10px;
        button {
            font-size: 1.75rem;
        }
    `}
    display: none;
    input {
      min-height: 1.5rem;
      font-size: 1.5rem;
      color: ${colors.secondary};
      background: ${colors.secondaryLight};
      width: 10rem;
      border-color: ${colors.secondaryLight};
      border-width: 1px;
      border-style: solid;
    }
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: ${colors.white};
    }
    @media (${breakpoints.tablet}) {
      display: initial;
    }
  `
})

const Panel = styled.div`
  background: ${colors.secondaryLight};
  color: ${colors.secondary};
  border-radius: 5px;
  padding: 10px;
  width: 50vw;
  height: 55vh;
  margin: auto;
  border: 1px solid ${colors.primary};
  div {
    overflow-y: auto;
    max-height: 92.5%;
  }
  input {
    width: 100%;
    font-size: 1.35rem;
    font-family: monospace, monospace;
    margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    color: ${colors.primary};
    transition: 0.25s;
    :hover {
      color: ${colors.primaryLight};
    }
  }
  span {
    a {
      font-size: 1.35rem;
    }
    sup {
      font-size: 0.65rem;
    }
    font-size: 0.75rem;
  }
`
