import React from 'react'
import GatsbyImage from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'

export default function Image(props) {
  const fluid = getFluidGatsbyImage(
    props?.node?.asset?._ref || props?.node?.asset?.id,
    { maxWidth: 1200, quality: 100 },
    config
  )

  return <GatsbyImage fluid={fluid} />
}
