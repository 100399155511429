import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { colors, breakpoints } from '../../styles/variables'

const query = graphql`
  query patentsQuery {
    sanitySiteSettings {
      patents {
        country
        numbers
      }
    }
  }
`

export default function Patents() {
  const queryData = useStaticQuery(query)
  const nodes = queryData?.sanitySiteSettings?.patents

  return (
    <Div>
      {nodes.map(node => {
        const { country, numbers } = node
        return (
          <>
            <h3>{country}</h3>
            {numbers.map(number => {
              return <div>{number}</div>
            })}
            <div style={{ height: '20px', width: '100%' }} />
          </>
        )
      })}
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 75vh;
  width: 50vw;
  margin: auto;
  font-size: 0.85rem;
  h3 {
    width: 100%;
    text-align: center;
  }
  div {
    width: 100%;
    text-align: center;
    color: ${colors.primary};
    @media (${breakpoints.tablet}) {
      width: 50%;
    }
    @media (${breakpoints.laptop}) {
      width: 35%;
    }
    @media (min-width: 1100px) {
      width: 30%;
    }
    @media (${breakpoints.desktop}) {
      width: 25%;
    }
    @media (${breakpoints.desktopLarge}) {
      width: 20%;
    }
  }
  @media (${breakpoints.tablet}) {
    h3,
    div {
      text-align: left;
    }
  }
`
