import { css } from '@emotion/core'
import styled from '@emotion/styled'
import * as variables from '../../styles/variables'

const { shadows, padding, breakpoints, screenWidths, colors } = variables

export const Launcher = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // margin-right: 2rem;

  svg {
    width: 2rem;
    height: 2rem;
  }

  @media (${breakpoints.laptop}) {
    display: none;
  }
`

export const DrawerBox = styled.div(
  props => css`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    max-width: ${screenWidths.phoneLarge};
    height: 100vh;
    background: white;
    box-shadow: ${shadows.high};

    transform: ${props.isActive ? `unset` : `translateX(-125%)`};
    transition: transform 0.3s;

    sup {
      font-size: 0.5rem;
    }

    @media (${breakpoints.laptop}) {
      display: none;
    }
  `
)

export const DrawerHeader = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 50px;
  justify-content: flex-start;

  sup {
    font-size: 0.5rem;
  }

  svg {
    cursor: pointer;
    font-size: 2.5rem;
    margin-right: 1rem;
  }
`

export const DrawerContent = styled.div`
  height: calc(100vh - 4rem);
  padding: ${padding.gutters};
  color: ${colors.secondary};
`

export const MenuItem = styled.div`
  margin-bottom: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  cursor: pointer;

  sup {
    font-size: 0.5rem;
  }
`

export const MenuItemInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  cursor: pointer;

  sup {
    font-size: 0.5rem;
  }
`

export const InnerItem = styled.div`
  display: flex;
  margin-bottom: -0.5rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  margin-left: 1rem;
  cursor: pointer;

  sup {
    font-size: 0.5rem;
  }
`

export const InnerBottom = styled.div`
  margin-bottom: 1.1rem;
`

export const Table = styled.table`
  overflow-y: scroll;
`

export const SlidingPanels = styled.div(
  props => css`
    display: flex;
    overflow: hidden;

    section {
      flex: 1 0 100%;
      transform: ${props.isMoved ? `translateX(-100%)` : `unset`};
      transition: transform 0.3s;
    }
  `
)

export const SubMenuHeading = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  user-select: none;

  sup {
    font-size: 0.5rem;
  }

  svg {
    font-size: 2rem;
    cursor: pointer;
  }
`
