import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Popup from 'reactjs-popup'
import { colors, breakpoints } from '../styles/variables'

const query = graphql`
  query {
    allSanitySiteSettings {
      nodes {
        externalLinkMessage
      }
    }
  }
`

export default function Modal(props) {
  const [open, setOpen] = useState(props?.open)
  const queryData = useStaticQuery(query)
  const { externalLinkMessage } = queryData?.allSanitySiteSettings?.nodes[0]
  const {
    className,
    id,
    key,
    to,
    text,
    externalMessage,
    component,
    cursor,
    fontSize,
    style
  } = props
  const children = component?.props?.children

  return (
    <>
      {!component ? (
        <div
          className={className}
          id={id}
          key={key}
          css={style}
          onClick={() => {
            setOpen(true)
          }}
        >
          {text || `Learn More`}
        </div>
      ) : (
        <div
          className={className}
          id={id}
          key={key}
          css={style}
          style={{ cursor: cursor, 'font-size': fontSize && `${fontSize}rem` }}
          onClick={() => {
            setOpen(true)
          }}
        >
          {children}
        </div>
      )}
      <Popup
        open={open}
        closeOnDocumentClick
        modal
        nested
        onClose={() => {
          setOpen(false)
        }}
      >
        <Div>
          <div>
            {externalMessage ||
              externalLinkMessage ||
              'You are now leaving EscaladeInc.com.'}
          </div>
          <div
            className="button"
            onClick={() => {
              setOpen(false)
            }}
          >
            <a href={to} target="_blank">
              I Understand
            </a>
          </div>
        </Div>
      </Popup>
    </>
  )
}

const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: ${colors.secondary};
  color: ${colors.secondaryLight};
  opacity: 0.95;
  border-radius: 5px;
  padding: 10px;
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    background: ${colors.secondaryLight};
    transition: 0.4s;
    cursor: pointer;
    :hover {
      border: 1px solid ${colors.secondaryLight};
      border-radius: 10px;
      background: ${colors.secondary};
      a {
        color: ${colors.secondaryLight};
      }
    }
  }
  a {
    padding: 10px;
    text-decoration: none;
    color: ${colors.secondary};
  }
  @media (${breakpoints.phoneSmall}) {
    .button {
      width: 50%;
    }
  }
  @media (${breakpoints.laptop}) {
    display: inline-block;
    width: 30vw;
    height: auto;
    margin: auto;
    .button {
      font-size: 120%;
      position: relative;
      // top: 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border-radius: 2.5px;
      width: auto;
      width: max-content;
      height: 20%;
      margin: auto;
    }
  }
`
