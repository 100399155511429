import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FileDisplay from '../file-display'

const query = graphql`
  query fileUploadQuery {
    allSanityFileAsset {
      nodes {
        assetId
        url
        originalFilename
      }
    }
  }
`

export default function FilesUpload(props) {
  const { files, heading, showHeading, anchor } = props?.node
  let refs = []
  for (let i = 0; i < files.length; i++) {
    refs.push(files[i]?.file?.asset?._ref || files[i]?.asset?._ref)
  }
  const queryData = useStaticQuery(query)
  const { nodes } = queryData?.allSanityFileAsset
  let content = []
  for (let i = 0; i < nodes.length; i++) {
    for (let j = 0; j < refs.length; j++) {
      if (
        nodes[i]?.assetId ===
        refs[j]?.substring(refs[j]?.indexOf('-') + 1, refs[j]?.lastIndexOf('-'))
      ) {
        content.push({
          title: files[j]?.title,
          file: {
            asset: {
              assetId: nodes[i]?.assetId,
              url: nodes[i]?.url,
              originalFilename: nodes[i]?.originalFilename
            }
          }
        })
      }
    }
  }

  const page = {
    files: content,
    heading,
    showHeading,
    anchor
  }

  return <FileDisplay page={page} />
}
