import React from 'react'
import SanityBlockContent from '@sanity/block-content-to-react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import FeatureContent from '../feature-content'
import * as types from './block-types'
import { breakpoints, colors, sizes, padding } from '../../styles/variables'

const Columns = styled.div(props => {
  return css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1rem;
    @media (${breakpoints.phoneLarge}) {
      margin: auto;
      max-width: ${sizes.constrainWidth};
      padding: 0 3rem;
    }
    ${props.centered &&
    `
        @media(${breakpoints.tablet}) {
          justify-content: center;
          margin-left: 15rem;
        }
      `}

    .column {
      width: 100%;

      @media (${breakpoints.laptop}) {
        width: calc(50% - 1.5rem);
      }

      font-size: 0.9rem;
      line-height: 1.75;

      h3 {
        color: ${colors.primary};
      }

      h1,
      h2,
      h3 {
        margin-bottom: 0;
      }

      p,
      ul {
        margin-top: 0;
      }
    }
  `
})

export default function DualColumnContent(props) {
  const { heading, headingColor, leftContent, rightContent, anchor, centered } =
    props?.node || {}

  return (
    <div id={anchor}>
      <FeatureContent
        heading={{
          text: heading,
          color: headingColor,
          align: `center`
        }}
      />
      <Columns centered={centered}>
        <SanityBlockContent
          className="column"
          renderContainerOnSingleChild
          blocks={leftContent}
          serializers={{ types }}
        />
        <SanityBlockContent
          className="column"
          renderContainerOnSingleChild
          blocks={rightContent}
          serializers={{ types }}
        />
      </Columns>
    </div>
  )
}
