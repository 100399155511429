import React, { useState } from 'react'
import { css } from '@emotion/core'
import Card from './card'
import Modal from './modal'
import { colors } from '../styles/variables'
import StockTicker from './stock-ticker'

export default function StockTickerDisplay(props) {
  const [open, setOpen] = useState(false)

  const nasdaq = () => setOpen(true)

  return (
    <Card css={styles} {...props}>
      <Modal
        to="https://www.nasdaq.com/market-activity/stocks/esca"
        externalMessage="The information on this page is provided by the NASDAQ, which is an entity is not affiliated with Escalade. Neither Escalade nor NASDAQ warrants the timeliness, accuracy, completeness, or usefulness of this information. You should not rely on this information in making any investment decision regarding Escalade’s securities. Any reliance you place on such information is strictly at your own risk. Escalade and NASDAQ disclaim all liability and responsibility for this information."
        component={
          <div>
            <StockTicker nasdaq={nasdaq} />
          </div>
        }
      />
    </Card>
  )
}

const styles = css`
  text-align: center;
  background: ${colors.primary};
  padding: 0;
`
