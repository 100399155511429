import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FileDisplay from '../file-display'

const query = graphql`
  query fileEntryQuery {
    allSanityFileAsset {
      nodes {
        assetId
        url
        originalFilename
      }
    }
  }
`

export default function FileEntry(props) {
  const { file, title } = props?.node
  const ref = file?.asset?._ref || file?.asset?.id
  const queryData = useStaticQuery(query)
  const content = queryData?.allSanityFileAsset?.nodes.find(
    node =>
      node?.assetId ===
      ref.substring(ref.indexOf('-') + 1, ref.lastIndexOf('-'))
  )
  const page = {
    files: [
      {
        title: title || 'View File',
        file: {
          asset: {
            assetId: content?.assetId,
            url: content?.url,
            originalFilename: content?.originalFilename
          }
        }
      }
    ]
  }

  return <FileDisplay page={page} />
}
