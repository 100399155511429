import React from 'react'
import Fallback from './fallback'
const Lazy = React.lazy(() => import('./lazy'))

// This has to be done this way because of react-trading-view-widget

export default function StockGraph(props) {
  const isSSR = typeof window === 'undefined'

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <Lazy {...props} />
        </React.Suspense>
      )}
    </>
  )
}
