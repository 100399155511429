import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { CgClose } from 'react-icons/cg'
import Break from './break'
import config from '../../../../config'
import { colors, breakpoints } from '../../styles/variables'

const query = graphql`
  query personnelQuery {
    boardQuery: allSanityPersonnel(
      sort: { fields: [board___order], order: ASC }
      filter: { board: { enabled: { eq: true } } }
    ) {
      nodes {
        name
        data: board {
          title
          caption
        }
        bio {
          sectionTitle
          hideHeading
          bar
          sectionText
        }
        headshot: _rawHeadshot(resolveReferences: { maxDepth: 5 })
      }
    }
    officersQuery: allSanityPersonnel(
      sort: { fields: [officers___order], order: ASC }
      filter: { officers: { enabled: { eq: true } } }
    ) {
      nodes {
        name
        data: officers {
          title
          caption
        }
        bio {
          sectionTitle
          hideHeading
          bar
          sectionText
        }
        headshot: _rawHeadshot(resolveReferences: { maxDepth: 5 })
      }
    }
    corporateQuery: allSanityPersonnel(
      sort: { fields: [corporate___order], order: ASC }
      filter: { corporate: { enabled: { eq: true } } }
    ) {
      nodes {
        name
        data: corporate {
          title
          caption
        }
        bio {
          sectionTitle
          hideHeading
          bar
          sectionText
        }
        headshot: _rawHeadshot(resolveReferences: { maxDepth: 5 })
      }
    }
    unitQuery: allSanityPersonnel(
      sort: { fields: [unit___order], order: ASC }
      filter: { unit: { enabled: { eq: true } } }
    ) {
      nodes {
        name
        data: unit {
          title
          caption
        }
        bio {
          sectionTitle
          hideHeading
          bar
          sectionText
        }
        headshot: _rawHeadshot(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`

export default function Personnel(props) {
  const { showBoard, showOfficers, showCorporate, showUnit } = props?.node

  const [page, setPage] = useState(
    showBoard ? 0 : showOfficers ? 1 : showCorporate ? 2 : showUnit ? 3 : null
  )
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({
    name: '',
    title: '',
    caption: '',
    bio: []
  })

  const queryData = useStaticQuery(query)
  const pages = [
    queryData?.boardQuery?.nodes,
    queryData?.officersQuery?.nodes,
    queryData?.corporateQuery?.nodes,
    queryData?.unitQuery?.nodes
  ]

  let checkLength = [showBoard, showOfficers, showCorporate, showUnit].filter(
    Boolean
  ).length

  return (
    <>
      {checkLength > 1 && (
        <Menu page={page}>
          {showBoard && (
            <span className="p0" onClick={() => setPage(0)}>
              Board of Directors
            </span>
          )}
          {showOfficers && (
            <span className="p1" onClick={() => setPage(1)}>
              Officers
            </span>
          )}
          {showCorporate && (
            <span className="p2" onClick={() => setPage(2)}>
              Corporate Leadership
            </span>
          )}
          {showUnit && (
            <span className="p3" onClick={() => setPage(3)}>
              Business Unit Leadership
            </span>
          )}
        </Menu>
      )}
      <Break node={{ height: '2' }} />
      <Div>
        {pages[page].map((person, index) => {
          const { name, data, bio, headshot } = person
          const { title, caption } = data
          return (
            <div className="block" key={`${page}-${index}`}>
              <div
                onClick={() => {
                  setData({
                    name: name,
                    title: title,
                    caption: caption,
                    bio: bio,
                    valid: true
                  })
                  setOpen(true)
                }}
              >
                <Img
                  className="headshot"
                  fluid={getFluidGatsbyImage(
                    headshot,
                    { maxWidth: 533.2, quality: 100 },
                    config
                  )}
                />
              </div>
              <div className="info">
                <span className="name">{name}</span>
                <Break node={{ height: '0.1' }} />
                <span className="title">{title}</span>
              </div>
            </div>
          )
        })}
        <div
          className={`backdrop${open ? ' open' : ''}`}
          onClick={() => setOpen(false)}
        />
        <div className={`pane${open ? ' open' : ''}`}>
          <Pane>
            <CgClose className="close" onClick={() => setOpen(false)} />
            <div className="header">
              <span className="name">{data.name}</span>
              <Break node={{ height: '0.1' }} />
              <span className="title">{data.title}</span>
              {data.caption && (
                <>
                  <Break node={{ height: '0.1' }} />
                  <span className="caption">{data.caption}</span>
                </>
              )}
            </div>
            <Break node={{ height: '1' }} />
            <div className="bio">
              {data.bio.map(section => {
                const { bar, hideHeading, sectionTitle, sectionText } = section
                return (
                  <>
                    <section>
                      {!bar && sectionTitle && (!hideHeading ?? true) && (
                        <span className="section-header">{sectionTitle}</span>
                      )}
                      {bar && (
                        <>
                          <br />
                          <span className="bar">
                            <div />
                          </span>
                        </>
                      )}
                      {(!hideHeading ?? true) && <br />}
                      <span className="section-body">{sectionText}</span>
                    </section>
                    <Break node={{ height: '0.5' }} />
                  </>
                )
              })}
            </div>
          </Pane>
        </div>
      </Div>
    </>
  )
}

const Menu = styled.div(props => {
  return css`
    background: ${colors.primary};
    color: ${colors.white};
    font-weight: bold;
    font-size: 0.75rem;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    > * {
      margin: 0 1vw;
      @media (${breakpoints.laptop}) {
        margin: 0 1rem;
      }
      height: 1.5rem;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        color: ${colors.primaryLight};
      }
    }
    .p${props.page} {
      color: ${colors.primaryLight};
    }
  `
})

const Div = styled.div(props => {
  return css`
      @keyframes fadeIn {
        0% {
          opacity: 0;
          transform:  translate(0px,80px);
        }
        100% {
          opacity: 1;
          transform:  translate(0px,0px);
        }
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 0.5rem;
      max-width: 1666px;
      margin: auto;
      @media(${breakpoints.phoneLarge}) {
          padding: 0 3rem;
      }
      .block {
        animation: fadeIn ease 0.75s;
        padding 1.25rem;
        .headshot {
          cursor: pointer;
          background: ${colors.secondary};
          height: 300px;
          width: 266.6px;
        }
        .info {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 10px;
          color: ${colors.white};
          background: ${colors.primary};
          opacity: 0.85;
          width: 266.6px;
          height: 90px;
          .name {
            font-size: 0.9rem;
            font-weight: bold;
          }
          .title {
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
      }
      .pane { 
        height: 100%;
        background: ${colors.white};
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        @media(${breakpoints.tablet}) {
          width: 70%;
        }
        @media(${breakpoints.netbook}) {
          width: 60%;
        }
        @media(${breakpoints.laptop}) {
          width: 50%;
        }
        @media(${breakpoints.desktop}) {
          width: 40%;
        }
        z-index: 200;
        box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
        transform: translateX(100%);
        transition: transform 0.3s ease-out;
     }
     .pane.open {
        transform: translateX(0);
     }

     .backdrop { 
        background: ${colors.secondaryLight};
        opacity: 0.35;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
      }
      .backdrop.open {
        width: 100vw;
        height: 100vh;
      }
    `
})

const Pane = styled.div(props => {
  return css`
    padding: 2rem;
    .close {
      cursor: pointer;
      font-size: 1.5rem;
    }
    .header {
      .name {
        font-size: 1.15rem;
        font-weight: bold;
      }
      .title {
        font-size: 0.7rem;
      }
      .caption {
        font-size: 0.7rem;
      }
    }
    .bio {
      overflow-y: auto;
      padding-right: 0rem;
      height: 45vh;
      @media (${breakpoints.phoneLarge}) {
        padding: 0.1rem;
        height: 50vh;
      }
      @media (${breakpoints.tabletSmall}) {
        padding: 0.15rem;
        height: 55vh;
      }
      @media (${breakpoints.tablet}) {
        padding-right: 0.25rem;
        height: 60vh;
      }
      @media (${breakpoints.netbook}) {
        padding-right: 0.5rem;
        height: 65vh;
      }
      @media (${breakpoints.laptop}) {
        padding-right: 0.75rem;
        height: 70vh;
      }
      @media (${breakpoints.desktop}) {
        padding-right: 1rem;
        height: 75vh;
      }
      section {
        .section-header {
          font-size: 0.8rem;
          font-weight: bold;
        }
        .bar {
          display: flex;
          justify-content: center;
          div {
            width: 65%;
            height: 2px;
            background: ${colors.secondary};
          }
        }
        .section-body {
          font-size: 0.7rem;
        }
      }
    }
  `
})
