import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import BlockContent from '../../../block-content'
import GatsbyImage from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import VerticalLogos from './vertical-logos'
import config from '../../../../../../config'
import { colors, fonts } from '../../../../styles/variables'
import styles from './styles'

export default function Tile(props) {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    window && setWidth(window.innerWidth)
  })

  const { name, brands, url, description, tileImage } = props

  const fluid = getFluidGatsbyImage(
    tileImage?.asset?._id,
    { maxWidth: 1000, quality: 100 },
    config
  )

  return (
    <div>
      <Card css={styles} key={`${name}-card`}>
        <GatsbyImage
          key={`${name}-image`}
          className="background-image"
          fluid={fluid}
          alt={name}
        />
        <div key={`${name}-overlay`} className="overlay" />
        <VerticalLogos key={`${name}-logos`} brands={brands} name={name} />
      </Card>
      <Div height={300}>
        <div className="content">
          <span>
            <b>{name}</b>
          </span>
          <BlockContent className="description" blocks={description} />
        </div>
      </Div>
      <Div height={10}>
        <div className="learn">
          <div className="bar" />
          <Link className="link" to={url}>
            <i>
              <b>Learn More</b>
            </i>
          </Link>
        </div>
      </Div>
    </div>
  )
}

const Card = styled.div`
  background-color: ${colors.secondary};
  color: ${colors.white};
  font-family: ${fonts.secondary};
  font-size: 1rem;
  font-weight: bold;
  width: 366px;
`

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-wrap: wrap;
    width: 366px;
    height: ${props?.height}px;
    background: ${colors.primary};
    padding: 50px;
    .content {
      color: ${colors.white};
      span {
        text-transform: uppercase;
      }
      .description {
        line-height: 1rem;
        font-size: 0.7rem;
      }
    }
    .learn {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .bar {
        width: 65px;
        height: 3px;
        background: ${colors.primaryLight};
      }
      .link {
        text-decoration: none;
        padding-left: 20px;
        color: ${colors.primaryLight};
        font-size: 0.8rem;
      }
    }
  `
})
