import React, { useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import _ from 'lodash'
import { Link, graphql, useStaticQuery } from 'gatsby'
import moment from 'moment-timezone'
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFilePowerpoint,
  FaFile
} from 'react-icons/fa'
import { colors, sizes, fonts, breakpoints } from '../../styles/variables'

const query = graphql`
  query newsQuery {
    sanityPressReleases {
      pressRelease {
        publish
        date
        heading
        subHeading
        body: _rawBody(resolveReferences: { maxDepth: 5 })
        file: _rawFile(resolveReferences: { maxDepth: 5 })
        fileTitle
      }
    }
  }
`

export default function News() {
  const [page, setPage] = useState(0)

  const queryData = useStaticQuery(query)
  const { pressRelease } = queryData?.sanityPressReleases
  const pressReleases = pressRelease.filter(release => {
    return release?.publish
  })
  const chunkedReleases = _.chunk(pressReleases.reverse(), 10)

  function getFileIcon(extension) {
    switch (extension) {
      case 'pdf':
        return <FaFilePdf />
      case 'xls':
      case 'xlsx':
        return <FaFileExcel />
      case 'doc':
      case 'docx':
        return <FaFileWord />
      case 'ppt':
      case 'pptx':
        return <FaFilePowerpoint />
      default:
        return <FaFile />
    }
  }

  function getFileClass(extension) {
    if (
      ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(extension)
    ) {
      return extension
    } else {
      return 'other'
    }
  }

  return (
    <Div>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>News</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {chunkedReleases[page]
            .sort((a, b) => {
              return moment(b?.date).toDate() - moment(a?.date).toDate()
            })
            .map(release => {
              const { date, heading, file } = release

              return (
                <tr
                  key={`${moment(date).format(
                    'MMMM DD, YYYY'
                  )}-${heading
                    .replace(/\s+/g, '-')
                    .replace(/&+/g, 'and')
                    .replace(/,+/g, '')
                    .replace(/%+/g, '')
                    .toLowerCase()}-${date
                    .replace(/\s+/g, '-')
                    .replace(/,+/g, '')
                    .toLowerCase()}`}
                >
                  <td className="date">
                    {moment(date).format('MMMM DD, YYYY')}
                  </td>
                  <td className="title">
                    <Link
                      to={`/${heading
                        .replace(/\s+/g, '-')
                        .replace(/&+/g, 'and')
                        .replace(/,+/g, '')
                        .replace(/%+/g, '')
                        .toLowerCase()}-${date
                        .replace(/\s+/g, '-')
                        .replace(/,+/g, '')
                        .toLowerCase()}`}
                    >
                      {heading}
                    </Link>
                  </td>
                  {file ? (
                    <td className="file">
                      <a
                        className={getFileClass(file?.asset?.extension)}
                        href={file?.asset?.url}
                        target="_blank"
                      >
                        {getFileIcon(file?.asset?.extension)}
                      </a>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
      <div className="pager">
        <span
          className={`clicker${page > 0 ? ` valid` : ``}`}
          onClick={() => {
            page > 0 && setPage(0)
          }}
        >
          {'⪡'}
        </span>
        &nbsp;&nbsp;
        <span
          className={`clicker${page > 0 ? ` valid` : ``}`}
          onClick={() => {
            page > 0 && setPage(page - 1)
          }}
        >
          {'<'}
        </span>
        &nbsp;
        <span className="number">{page + 1}</span>
        &nbsp;
        <span
          className={`clicker${
            page < chunkedReleases.length - 1 ? ` valid` : ``
          }`}
          onClick={() => {
            page < chunkedReleases.length - 1 && setPage(page + 1)
          }}
        >
          {'>'}
        </span>
        &nbsp;&nbsp;
        <span
          className={`clicker${
            page < chunkedReleases.length - 1 ? ` valid` : ``
          } `}
          onClick={() => {
            page < chunkedReleases.length - 1 &&
              setPage(chunkedReleases.length - 1)
          }}
        >
          {'⪢'}
        </span>
      </div>
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    @media (${breakpoints.phoneLarge}) {
      padding: 0 3rem;
    }
    max-width: ${sizes.constrainWidth};
    margin: auto;
    .pager {
      text-align: center;
      cursor: default;
      user-select: none;
      margin-top: 2rem;
      .clicker {
        font-size: 2.5rem;
        color: ${colors.secondaryLight};
        font-family: ${fonts.secondary};
        cursor: default;
        font-weight: 600;
      }
      .clicker.valid {
        font-weight: 600;
        cursor: pointer;
        color: ${colors.secondary};
      }
      .number {
        font-size: 2.25rem;
        font-weight: 600;
      }
      @media (${breakpoints.laptop}) {
        text-align: left;
        margin-top: 0.5rem;
        .clicker {
          font-size: 1.5rem;
        }
        .number {
          font-size: 1.25rem;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        text-align: left;
        body-bottom: 1px solid ${colors.secondary};
        tr {
          th {
            padding: 5px;
            color: ${colors.secondary};
            font-size: 0.75rem;
            @media (${breakpoints.phoneLarge}) {
              font-size: 1rem;
            }
          }
        }
      }
      tbody {
        overflow-y: auto;
        max-height: 500px;
        tr {
          td {
            padding: 5px;
            font-weight: light;
            font-size: 0.75rem;
            @media (${breakpoints.phoneLarge}) {
              font-size: 1rem;
            }
            span {
              font-size: 0.5rem;
              @media (${breakpoints.phoneLarge}) {
                font-size: 0.75rem;
              }
            }
          }
          .title {
            color: ${colors.primary};
            width: 90%;
          }
          .date {
            color: ${colors.secondary};
            width: 15%;
          }
          .file {
            font-size: 1.5rem;
            .pdf {
              color: #ff0000;
              :hover {
                color: ${colors.primary};
              }
            }
            .doc,
            .docx {
              color: #00a4ef;
              :hover {
                color: ${colors.primary};
              }
            }
            .xls,
            .xlsx {
              color: #008000;
              :hover {
                color: ${colors.primary};
              }
            }
            .ppt,
            .pptx {
              color: #d04423;
              :hover {
                color: ${colors.primary};
              }
            }
            .other {
              color: #00a4ef;
              transition: 0.4s;
              :hover {
                color: ${colors.primary};
              }
            }
          }
          a {
            text-decoration: none;
            color: ${colors.primary};
            transition: 0.4s;
            :hover {
              color: ${colors.primaryLight};
            }
          }
          :nth-child(odd) {
            background: ${colors.secondaryLight}60;
          }
        }
      }
    }
  `
})
