import React from 'react'
import { css } from '@emotion/core'
import BlockContent from '../block-content'
import { breakpoints, sizes } from '../../styles/variables'

export default function Page(props) {
  return (
    <BlockContent
      css={styles(
        props?.node?.lineHeight,
        props?.node?.fontSize,
        props?.node?.padding
      )}
      blocks={props?.node?.pageContent}
    />
  )
}

const styles = (lineHeight, fontSize, padding) => css`
  display: block;
  padding-bottom: 2rem;
  ${lineHeight && `line-height: ${lineHeight};`}
  ${fontSize && `font-size: ${fontSize}rem;`}
    padding: 0 1rem;
  @media (${breakpoints.phoneLarge}) {
    max-width: ${sizes.constrainWidth};
    margin: auto;
    padding: 0 ${padding || '3'}rem !important;
  }
`
