import React from 'react'
import { Bubble } from 'react-chartjs-2'

export default function BubbleGraph(props) {
  const {
    datasets,
    labels,
    width,
    height,
    stacked,
    legend,
    hideLegend,
    title
  } = props?.graph
  const options = {
    title: {
      display: true,
      text: title,
      fontSize: 20
    },
    legend: {
      display: !hideLegend,
      position: legend
    },
    scales: {
      yAxes: [
        {
          stacked: stacked,
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          stacked: stacked,
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    maintainAspectRatio: false
  }

  return (
    <Bubble
      data={{ labels, datasets }}
      width={width}
      height={height}
      options={options}
    />
  )
}
