import styled from '@emotion/styled'
import { colors, breakpoints } from '../styles/variables'

export const Divider = styled.div`
  width: 100%;
  height: 1.5rem;
  background: ${colors.primary};
`

export const DividerBottom = styled.div`
  width: 95%;
  height: auto;
  background: ${colors.primary};
  padding: 10px;
  margin-left: 1rem;
  a {
    text-decoration: none;
    transition: 0.4s;
  }
  a:hover {
    color: ${colors.primaryLight};
  }
  @media (${breakpoints.smallest}) {
    display: flex;
    flex-wrap: wrap;
  }
`
