import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'
import Button from '../components/button'
import Modal from './modal'
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaPinterest,
  FaLinkedin
} from 'react-icons/fa'

export default function ContentButtons(props) {
  const { brand } = props

  function getIcon(icon) {
    switch (icon) {
      case 'Facebook':
        return <FaFacebookSquare />
      case 'Twitter':
        return <FaTwitter />
      case 'Instagram':
        return <FaInstagram />
      case 'Youtube':
        return <FaYoutube />
      case 'Pinterest':
        return <FaPinterest />
      case 'Linkedin':
        return <FaLinkedin />
    }
  }

  return (
    <div css={styles}>
      {brand.link && (
        <Button
          secondary
          fontSize="0.85"
          hoverTextColor={colors.primary}
          externalMessage={brand?.externalMessage}
          link={`${brand.link}`}
          text={`Visit ${brand.linkText || brand.brand}`}
          target="_blank"
        />
      )}
      <Div>
        {brand.buttons.map((button, i) => {
          const key = `${brand.brand}-${button.text}-${i}`

          return (
            <Modal
              to={button.link}
              externalMessage={`You are now leaving Escaladeinc.com and going to ${brand.brand}'s ${button.text} page. ${button.text}.com is an external site maintained and operated by a third party. Escalade is not responsible for and does not approve or endorse information provided by this third party. Escalade does not manage, maintain, control, monitor, or in any way assure the timeliness, accuracy, completeness, or usefulness of the content available on or through this external third party site.`}
              className="logo-button"
              key={key}
              component={<>{getIcon(button.text)}</>}
            />
          )
        })}
      </Div>
    </div>
  )
}

const styles = css`
  display: block;
  flex-flow: row wrap;
  padding: 15px 0;
  font-size: 1.5rem;
`

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-wrap: wrap;
    .logo-button {
      margin-top: 10px;
      margin-right: 0.5rem;
      font-size: 1.3rem;
      color: ${colors.secondary};
      transition: 0.4s;
      cursor: pointer;
      :hover {
        color: ${colors.primary};
      }
    }
  `
})
