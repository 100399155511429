import React, { useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileCode,
  FaFile
} from 'react-icons/fa'
import { colors, breakpoints, sizes } from '../../styles/variables'

// If you're here trying to fix a bug with the SEC widget check the rss-parser plugin
// You may need to increase the number in the for-loop conditional

export default function Sec() {
  // year's initial value is set by a useEffect once the unique years have been determined
  const [year, setYear] = useQueryParam('year', StringParam)
  const [group = 'All', setGroup] = useQueryParam('group', StringParam)
  const [type = 'All', setType] = useQueryParam('type', StringParam)
  const [startDate = '', setStartDate] = useQueryParam('startDate', StringParam)
  const [endDate = '', setEndDate] = useQueryParam('endDate', StringParam)

  // Leaving this here in case someone decides they want to change it back again
  // const [year, setYear] = useState('')
  // const [group, setGroup] = useState('All')
  // const [type, setType] = useState('All')
  // const [startDate = '', setStartDate] = useState('')
  // const [endDate = '', setEndDate] = useState('')

  const feed = useStaticQuery(graphql`
    query {
      secrss {
        items {
          title
          type
          name
          date
          group
          pdf
          word
          xls
          html
          xbrl
        }
      }
    }
  `)

  const { items } = feed.secrss

  // if (!items[0].title) return <></>

  const filings = [...items]
    .filter(item => {
      return item?.group === group || group === 'All'
    })
    .filter(item => {
      return item?.type === type || type === 'All'
    })
    .filter(item => {
      return (
        item?.date.substring(0, item?.date.indexOf('-')) === year ||
        year === 'All'
      )
    })
    .filter(item => {
      if (!startDate && endDate) {
        if (item?.date <= endDate) {
          return true
        }
      } else if (startDate && !endDate) {
        if (item?.date >= startDate) {
          return true
        }
      } else if (startDate && endDate) {
        if (item?.date >= startDate && item?.date <= endDate) {
          return true
        }
      } else if (!(startDate && endDate)) {
        return true
      }
      return false
    })

  const groups = [
    ...new Set(
      items.map(item => {
        return item?.group
      })
    )
  ]
  const types = [
    ...new Set(
      items.map(item => {
        return item?.type
      })
    )
  ]
  const years = [
    ...new Set(
      items.map(item => {
        return `${item?.date.substring(0, item?.date.indexOf('-'))}`
      })
    )
  ]

  useEffect(() => {
    setYear(`${years[0]}`)
  }, [])

  const filingsMessage = `(${filings.length})`

  function groupProccessing(group) {
    switch (group) {
      case 'regstmt':
        return 'Registration Statements'
      case 'Proxy Stmts':
        return 'Proxy Statements'
      case '8k':
        return 'Current Reports'
      case 'Insider Trading':
        return 'Stock Ownership'
      case '-':
        return 'Correspondence'
      default:
        return group
    }
  }

  return (
    <>
      {items[0].title && (
        <Feed>
          <div className="table">
            <fieldset className="filter-desktop">
              <legend>Filters</legend>
              <div className="top">
                <div className="group">
                  <select
                    defaultValue={'All'}
                    value={group}
                    onChange={e => setGroup(e.target.value)}
                  >
                    <option value="All">All Filing Groups</option>
                    {groups.map(group => (
                      <option value={group}>{groupProccessing(group)}</option>
                    ))}
                  </select>
                </div>
                <div className="type">
                  <select
                    defaultValue={'All'}
                    value={type}
                    onChange={e => setType(e.target.value)}
                  >
                    <option value="All">All Filing Types</option>
                    {types.map(type => (
                      <option value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                <div className="year">
                  <select
                    defaultValue="All"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                  >
                    <option value="All">All Years</option>
                    {years.map(year => (
                      <option value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="bottom">
                <div className="date">
                  From&nbsp;
                  <input
                    type="date"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                  />
                  &nbsp; To&nbsp;
                  <input
                    type="date"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                  />
                </div>
                <div className="reset">
                  <button
                    onClick={() => {
                      setGroup('All')
                      setYear('All')
                      setType('All')
                      setStartDate('')
                      setEndDate('')
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </fieldset>
            <div className="table-wrapper">
              <table className="desktop">
                <thead>
                  <tr>
                    <th key="type">Form</th>
                    <th key="date">Date</th>
                    <th key="filings">
                      <a
                        href="https://www.sec.gov/edgar/browse/?CIK=33488"
                        target="_blank"
                      >
                        Filing
                      </a>
                      &emsp;
                      <span>{filingsMessage}</span>
                    </th>
                    <th key="files">Documents</th>
                  </tr>
                </thead>
                <tbody>
                  {filings.map(item => {
                    return (
                      <>
                        <tr key={item.id}>
                          <td className="type">{item.type}</td>
                          <td className="date">
                            {`${item.date.substring(
                              item.date.indexOf('-') + 1
                            )}-${item.date.substring(
                              0,
                              item.date.indexOf('-')
                            )}`}
                          </td>
                          <td className="title">{item.name}</td>
                          <td className="files">
                            <div>
                              {item.pdf && (
                                <a
                                  className="pdf"
                                  href={item.pdf}
                                  target="_blank"
                                >
                                  <FaFilePdf />
                                </a>
                              )}
                              {item.word && (
                                <a
                                  className="word"
                                  href={item.word}
                                  target="_blank"
                                >
                                  <FaFileWord />
                                </a>
                              )}
                              {item.xls && (
                                <a
                                  className="xls"
                                  href={item.xls}
                                  target="_blank"
                                >
                                  <FaFileExcel />
                                </a>
                              )}
                              {item.html && (
                                <a
                                  className="html"
                                  href={item.html}
                                  target="_blank"
                                >
                                  <FaFileCode />
                                </a>
                              )}
                              {item.xbrl && (
                                <a
                                  className="xbrl"
                                  href={item.xbrl}
                                  target="_blank"
                                >
                                  <FaFile />
                                </a>
                              )}
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <fieldset className="filter-mobile">
              <legend>Filters</legend>
              <div className="top">
                <div className="group">
                  <select
                    defaultValue={'All'}
                    value={group}
                    onChange={e => setGroup(e.target.value)}
                  >
                    <option value="All">All Filing Groups</option>
                    {groups.map(group => (
                      <option value={group}>{groupProccessing(group)}</option>
                    ))}
                  </select>
                </div>
                <div className="type">
                  <select
                    defaultValue={'All'}
                    value={type}
                    onChange={e => setType(e.target.value)}
                  >
                    <option value="All">All Filing Types</option>
                    {types.map(type => (
                      <option value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                <div className="year">
                  <select
                    defaultValue="All"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                  >
                    <option value="All">All Years</option>
                    {years.map(year => (
                      <option value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="bottom">
                <div className="date">
                  From&emsp;
                  <input
                    type="date"
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                  />
                  &emsp; To&emsp;
                  <input
                    type="date"
                    value={endDate}
                    onChange={e => setEndDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="reset">
                <button
                  onClick={() => {
                    setGroup('All')
                    setYear('All')
                    setType('All')
                    setStartDate('')
                    setEndDate('')
                  }}
                >
                  Reset
                </button>
              </div>
            </fieldset>
            <table className="mobile">
              <thead>
                <tr>
                  <th key="filings">
                    <a
                      href="https://www.sec.gov/edgar/browse/?CIK=33488"
                      target="_blank"
                    >
                      SEC Filings
                    </a>
                    &emsp;
                    <span>{filingsMessage}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filings.map(item => {
                  return (
                    <>
                      <tr key={item.id}>
                        <td className="title">
                          {item.title}
                          <div className="date">
                            {`${item.date.substring(
                              item.date.indexOf('-') + 1
                            )}-${item.date.substring(
                              0,
                              item.date.indexOf('-')
                            )}`}
                          </div>
                          <div className="files">
                            <div>
                              {item.pdf && (
                                <a
                                  className="pdf"
                                  href={item.pdf}
                                  target="_blank"
                                >
                                  <FaFilePdf />
                                </a>
                              )}
                              {item.word && (
                                <a
                                  className="word"
                                  href={item.word}
                                  target="_blank"
                                >
                                  <FaFileWord />
                                </a>
                              )}
                              {item.xls && (
                                <a
                                  className="xls"
                                  href={item.xls}
                                  target="_blank"
                                >
                                  <FaFileExcel />
                                </a>
                              )}
                              {item.html && (
                                <a
                                  className="html"
                                  href={item.html}
                                  target="_blank"
                                >
                                  <FaFileCode />
                                </a>
                              )}
                              {item.xbrl && (
                                <a
                                  className="xbrl"
                                  href={item.xbrl}
                                  target="_blank"
                                >
                                  <FaFile />
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
            <span className="cik">
              CIK:&nbsp;
              <a
                href="https://www.sec.gov/edgar/browse/?CIK=33488"
                target="_blank"
              >
                (0000033488)
              </a>
            </span>
          </div>
        </Feed>
      )}
    </>
  )
}

const Feed = styled.div(props => {
  return css`
    @media (${breakpoints.tablet}) {
      max-width: ${sizes.constrainWidth};
      margin: auto;
    }
    fieldset {
      border: 1px solid black;
    }
    .table > span > a {
      text-decoration: none;
      color: ${colors.black};
    }
    .filter-desktop {
      display: none;
      margin-bottom: 0.5rem;
      font-size: 0.85rem;
      .top,
      .bottom {
        display: flex;
      }
      select {
        font-size: 0.85rem;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
      }
      input[type='date'] {
        width: 9rem;
        cursor: text;
      }
      select {
        width: 9rem;
        cursor: pointer;
      }
      button {
        margin-left: 0.6rem;
        width: 5rem;
        font-size: 0.85rem;
        cursor: pointer;
      }
      @media (${breakpoints.tablet}) {
        display: initial;
      }
    }
    .filter-mobile {
      margin-left: auto;
      margin-right: auto;
      display: initial;
      width: 50%;
      max-width: 100vw;
      margin-bottom: 0.5rem;
      font-size: 0.85rem;
      .top,
      .bottom {
        display: flex;
      }
      select,
      input:not([type='date']) {
        font-size: 0.85rem;
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
      }
      input:not([type='date']) {
        width: 21.65rem;
      }
      input[type='date'] {
        width: 8rem;
        cursor: text;
      }
      select {
        width: 10.7rem;
        cursor: pointer;
      }
      @media (${breakpoints.tablet}) {
        display: none;
      }
      @media (max-width: 768px) {
        width: 100%;
        .top {
          display: flex;
          flex-wrap: wrap;
        }
        .bottom {
          display: block;
        }
        select {
          width: 10rem;
        }
        input[type='date'] {
          width: 7.5rem;
        }
      }
      @media (max-width: 500px) {
        width: 25%;
        .top,
        .bottom {
          display: block;
        }
        select {
          width: 10rem;
        }
        input[type='date'] {
          width: 7.3rem;
        }
      }
      @media (max-width: 410px) {
        width: 35%;
        .top,
        .bottom {
          display: block;
        }
        select {
          width: 10rem;
        }
        input[type='date'] {
          width: 7.2rem;
        }
      }
    }
    .desktop {
      display: none;
      border-collapse: collapse;
      thead {
        border-bottom: 2px solid black;
        tr {
          th {
            padding-right: 4.25rem;
            text-align: left;
            color: ${colors.primary};
            a {
              color: ${colors.primary};
              text-decoration: none;
            }
            span {
              font-size: 0.75rem;
            }
          }
          .type {
            width: 20%;
          }
          .date {
            width: 20%;
          }
          .title {
            width: 80%;
          }
          .files {
            width: 100%;
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: 1px solid black;
            a {
              text-decoration: none;
              color: black;
              svg {
                position: relative;
                top: 2.5px;
                width: 25px;
                height: 25px;
                cursor: pointer;
              }
            }
            a:hover {
              color: ${colors.primary};
            }
            .pdf {
              color: #ff0000;
            }
            .word {
              color: #00a4ef;
            }
            .xls {
              color: #008000;
            }
            .html {
              color: #e34c26;
            }
            .xbrl {
              color: #00a4ef;
            }
          }
          .type,
          .date {
            font-size: 0.85rem;
          }
          .type {
            width: 20%;
          }
          .date {
            width: 20%;
          }
          .title {
            font-size: 0.75rem;
            width: 100%;
            padding-right: 2rem;
          }
          .files {
            width: 100%;
            div {
              display: flex;
            }
          }
          :nth-child(even) {
            background: ${colors.secondaryLight};
          }
        }
      }
      @media (${breakpoints.tablet}) {
        display: inherit;
      }
    }
    .mobile {
      border-collapse: collapse;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      thead {
        display: block;
        border-bottom: 2px solid black;
        tr {
          th {
            text-align: left;
            color: ${colors.primary};
            width: 20rem;
            a {
              color: ${colors.primary};
              text-decoration: none;
            }
            span {
              font-size: 0.75rem;
            }
          }
        }
      }
      tbody {
        display: block;
        max-height: 525px;
        overflow: auto;
        tr {
          td {
            border-bottom: 1px solid black;
            // width: 28rem;
            display: block;
            a {
              text-decoration: none;
              color: black;
            }
            a:hover {
              color: ${colors.primary};
            }
            div {
              margin-top: 10px;
            }
          }
          .title {
            font-size: 1rem;
            // padding-right: 7rem;
          }
          .files {
            font-size: 2rem;
          }
          .pdf {
            color: #ff0000;
          }
          .word {
            color: #00a4ef;
          }
          .xls {
            color: #008000;
          }
          .html {
            color: #e34c26;
          }
          .xbrl {
            color: #00a4ef;
          }
          :nth-child(even) {
            background: ${colors.secondaryLight};
          }
        }
      }
      @media (${breakpoints.tablet}) {
        display: none;
      }
    }
  `
})
