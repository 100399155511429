import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import moment from 'moment-timezone'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'
import { colors, breakpoints, sizes } from '../../styles/variables'

const query = graphql`
  query eventsQuery {
    sanityEvents {
      events {
        eventTitle
        location
        time
        date
        links: _rawLinks
        image: _rawImage
      }
    }
  }
`

export default function Events() {
  const queryData = useStaticQuery(query)
  const { events } = queryData?.sanityEvents
  const current = events
  .filter(event => {
    return moment(event?.date) >= moment.now();
  })
  .sort((a, b) => {
    return moment(a?.date).toDate() - moment(b?.date).toDate()
  });

  const filtered = events
    .filter(event => {
      return moment(event?.date) < moment.now();
    })
    .sort((a, b) => {
      return moment(b?.date).toDate() - moment(a?.date).toDate()
    });

  const fluid = getFluidGatsbyImage(
    current[0]?.image?.asset?._ref || current[0]?.image?.asset?.id,
    { maxWidth: 1000, quality: 100 },
    config
  )

  const [dim, setDim] = useState({ dimension: '', size: '' })

  useEffect(() => {
    let img = new Image()
    img.src = fluid?.src
    img.onload = () => {
      if (img.height > img.width) {
        setDim({ dimension: 'h', size: '30' })
      } else if (img.width > img.height) {
        setDim({ dimension: 'w', size: '30' })
      } else {
        setDim({ dimension: 's', size: '25' })
      }
    }
  }, [])

  function getDim() {
    switch (dim.dimension) {
      case 'h':
        return `
                    height: ${dim.size * 1.25}vw;
                    width: auto;
                    @media(${breakpoints.tablet}) {
                        height: ${dim.size}vh;
                    }
                `
      case 'w':
        return `
                    width: ${dim.size * 2}vw;
                    height: auto;
                    @media(${breakpoints.tablet}) {
                        width: ${dim.size}vw;
                    }
                `
      case 's':
        return `
                    width: ${dim.size * 2}vw;
                    height: auto;
                    @media(${breakpoints.tablet}) {
                        width: ${dim.size}vw;
                    }
                `
      default:
        return `width: 0;`
    }
  }

  return (
    <Div>
    {current.map(event => {
        return (
      <div className="current">
        {/* <h2>Event</h2> */}
        <h2 />
            <div className="set">
              <h3>{event?.eventTitle}</h3>
              <div>
                {moment(event?.date).format('MMMM DD, YYYY')}, {event?.time}
              </div>
              <div><div dangerouslySetInnerHTML={{__html: event?.location.split(/\n/).join('</br>')}} /></div>
              {event?.links !== null && (
                <div className="links">
                  {event?.links.map(link => {
                    return (
                      <a href={link?.link} target="_blank">
                        {link?.text}
                      </a>
                    )
                  })}
                </div>
              )}
            </div>
            {/* {current.image && (
              <img
                css={css`
                  ${getDim()}
                `}
                src={fluid?.src}
              />
            )} */}
          </div>
          )
        })}
      {filtered.length !== 0 && (
        <div className="current">
          <h2>Past Events</h2>
          {filtered.map(event => {
            return (
              <div className="set">
                <h3>{event?.eventTitle}</h3>
                <div>{moment(event?.date).format('MMMM DD, YYYY')}</div>
                <div><div dangerouslySetInnerHTML={{__html: event?.location.split(/\n/).join('</br>')}} /></div>
                {event?.links !== null && (
                  <div className="links">
                    {event?.links.map(link => {
                      return (
                        <a href={link?.link} target="_blank">
                          {link?.text}
                        </a>
                      )
                    })}
                  </div>
                )}
                <hr />
              </div>
            )
          })}
        </div>
      )}
    </Div>
  )
}

const Div = styled.div`
    padding: 0 1rem;
    @media(${breakpoints.phoneLarge}) {
        max-width: ${sizes.constrainWidth};
        padding 0 3rem;
        margin: auto;
    }
    display: flex;
    flex-wrap: wrap;
    h2 {
        color: ${colors.primary};
        font-weight: light;
    }
    .current {
        @media(${breakpoints.phoneLarge}) {
            margin-right: 15rem;
        }
        img {
            width: 15vw;
            border: 1px solid ${colors.primary};
        }
        width: 100%;
    }
    .set {
        background: ${colors.secondaryLight}30;
        div:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
    .links {
        display: flex;
        flex-wrap: wrap;
        a {
            padding-right: 10px;
            text-decoration: none;
            color: ${colors.primary};
            transition: 0.4s;
            :hover {
                color: ${colors.primaryLight};
            }
        }
    }
    .past {
        height: auto;
        overflow-y: auto;
        margin-bottom: 2rem;
        .set {
            width: 12.5rem;
        }
    }
    hr {
        margin-bottom: 0;
    }
    @media(${breakpoints.tablet}) {
        .past {
            height: 455px;
            overflow-y: auto;
        }
    }
`
