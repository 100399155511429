import React, { useState } from 'react'
import { FaBars as MenuIcon } from 'react-icons/fa'
import Drawer from './drawer'
import Navigation from './navigation'
import { Launcher } from './styled'

export default function MobileMenu(props) {
  const [isActive, setIsActive] = useState(false)

  const show = () => setIsActive(true)
  const hide = () => setIsActive(false)

  const { navigation } = props

  return (
    <>
      <Launcher onClick={show}>
        <MenuIcon />
      </Launcher>
      <Drawer isActive={isActive} onClose={hide}>
        <Navigation navigation={navigation} />
      </Drawer>
    </>
  )
}
