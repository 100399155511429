import React from 'react'
import { css } from '@emotion/core'
import GatsbyImage from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import config from '../../../../config'

export default function Signature(props) {
  const fluid = getFluidGatsbyImage(
    props?.node?.asset?._ref || props?.node?.asset?.id,
    { maxWidth: 200, quality: 100 },
    config
  )

  return <GatsbyImage css={style} fluid={fluid} />
}

const style = css`
  width: 40%;
`
