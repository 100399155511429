import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { css } from '@emotion/core'
import config from '../../../config'
import Card from './card'
import { breakpoints } from '../styles/variables'

export default function IconTile({
  media,
  altBackground,
  background,
  heading,
  height,
  width,
  radius
}) {
  const [dim, setDim] = useState({ dimension: '', rem: '' })
  const [windowWidth, setWidth] = useState(0)

  useEffect(() => {
    window && setWidth(window.innerWidth)
  })

  // This used to actually return different dimmensions, I haven't got the time to remove it yet and make the funcaionality simpler.
  useEffect(() => {
    let img = new Image()
    img.src = media
    img.onload = () => {
      if (img.height > img.width) {
        setDim({ dimension: 'h', rem: img.height - img.width < 50 ? '5' : '8' })
      } else if (img.width > img.height) {
        setDim({ dimension: 'w', rem: img.width - img.height < 50 ? '5' : '8' })
      } else {
        setDim({ dimension: 's', rem: '4.5' })
      }
    }
  }, [])

  // This used to actually return different dimmensions, I haven't got the time to remove it yet and make the funcaionality simpler.
  function getDim() {
    switch (dim.dimension) {
      case 'h':
        return `.logo { width: ${dim.rem}rem; height: ${dim.rem}rem; }`
      case 'w':
        return `.logo { width: ${dim.rem}rem; height: ${dim.rem}rem; }`
      case 's':
        return `.logo { width: ${dim.rem}rem; height: ${dim.rem}rem; }`
      default:
        return `.logo { width: 0; }`
    }
  }

  function getFormatting(height, width, radius) {
    return css`
      ${height && `min-height: ${height}`}px;
      @media (mine-width: 623px) {
        min-width: 100%;
      }
      @media (${breakpoints.laptop}) {
        ${width && `min-width: ${width}`}px;
      }
      .background-image {
        ${radius && `border-radius: ${radius}`}%;
      }
    `
  }

  const fluid = getFluidGatsbyImage(background, { maxWidth: 1200 }, config)

  return (
    <Card
      css={css`
        ${styles({ windowWidth })}
        ${getDim()}
        ${getFormatting(height, width, radius)}
      `}
    >
      <Img
        key={`${heading?.text?.replace(/\s+/g, '-')}-image`}
        className="background-image"
        fluid={fluid}
        alt={altBackground}
      />
      <div className="overlay" />
      {typeof media === `string` ? (
        <img className="logo" src={media} alt={heading?.text} />
      ) : (
        <Img className="logo" fluid={media} alt={heading?.text} />
      )}
    </Card>
  )
}

const styles = props => css`
  min-height: 200px;
  min-width: 300px;
  width: 100%;
  position: relative;
  background: none;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    z-index: 1;
    max-width: ${props?.windowWidth}px;
    @media (${breakpoints.phoneLarge}) {
      max-width: ${props?.windowWidth - 108}px;
    }
    // width: 100%;
    // height: 100%;
    position: absolute !important;
    // left: 29%;
    top: 29%;
  }

  .background-image {
    board-radius: 0;
    width: 100%;
    height: 100%;
    max-width: ${props?.windowWidth}px;
    @media (${breakpoints.phoneLarge}) {
      max-width: ${props?.windowWidth - 108}px;
    }
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
  }

  .overlay {
    board-radius: 0;
    width: 100%;
    height: 100%;
    max-width: ${props?.windowWidth}px;
    @media (${breakpoints.phoneLarge}) {
      max-width: ${props?.windowWidth - 108}px;
    }
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 0.35;

    object-fit: cover;
  }
`
