import React from 'react'
import { Polar } from 'react-chartjs-2'

export default function PolarGraph(props) {
  const {
    datasets,
    labels,
    width,
    height,
    legend,
    hideLegend,
    title
  } = props?.graph
  const data = {
    labels,
    borderWidth: datasets.borderWidth,
    datasets: [
      {
        data: datasets.data.map(data => {
          return data?.data
        }),
        backgroundColor: datasets.data.map(data => {
          return data?.backgroundColor
        }),
        borderColor: datasets.data.map(data => {
          return data?.borderColor
        }),
        hoverBackgroundColor: datasets.data.map(data => {
          return data?.hoverBackgroundColor
        }),
        hoverBorderColor: datasets.data.map(data => {
          return data?.hoverBorderColor
        })
      }
    ]
  }
  const options = {
    title: {
      display: true,
      text: title,
      fontSize: 20
    },
    legend: {
      display: !hideLegend,
      position: legend
    },
    maintainAspectRatio: false
  }

  return <Polar data={data} width={width} height={height} options={options} />
}
