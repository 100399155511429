import styled from '@emotion/styled'
import { breakpoints } from '../styles/variables'

const MediaCardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  > * {
    margin: 1rem;
    width: calc(100% - 2rem);

    @media (${breakpoints.tablet}) {
      width: calc(100% / 2 - 2rem);
    }

    @media (${breakpoints.laptop}) {
      width: calc(100% / 3 - 3rem);
    }
    @media (${breakpoints.desktop}) {
      width: calc(100% / 3 - 8rem);
    }
  }
`

export default MediaCardGrid
