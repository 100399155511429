import React from 'react'
import styled from '@emotion/styled'

export default function Anchor(props) {
  return <Div id={`${props?.node?.anchor}`} />
}

const Div = styled.div`
  width: 100%;
`
