import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import MediaCardGrid from '../media-card-grid'
import MediaCard from '../media-card'
import { sizes } from '../../styles/variables'

const query = graphql`
  query locationsQuery {
    sanitySiteSettings {
      imageTitleOverlay
      forcedTitleHeight
      forcedSubTitleHeight
      forcedCaptionHeight
      forcedBodyHeight
    }
    allSanityLocations(sort: { fields: [order], order: ASC }) {
      nodes {
        order
        heading
        subHeading
        caption
        align
        url
        secondaryDescription
        link
        linkText
        externalLink
        externalMessage
        imageAlt
        image: _rawImage(resolveReferences: { maxDepth: 10 })
        height
        width
        radius
        headingColor
        subHeadingColor
        captionColor
        bodyColor
        headingBackground
        subHeadingBackground
        captionBackground
        bodyBackground
        body: _rawBody(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`

export default function Locations(props) {
  const queryData = useStaticQuery(query)
  const locations = queryData?.allSanityLocations?.nodes
  const {
    imageTitleOverlay,
    forcedTitleHeight,
    forcedSubTitleHeight,
    forcedCaptionHeight,
    forcedBodyHeight
  } = queryData?.sanitySiteSettings

  return (
    <MediaCardGrid css={gridStyles}>
      {locations.map((card, i) => {
        const {
          _key,
          heading,
          subHeading,
          caption,
          body,
          align,
          imageAlt,
          image,
          height,
          width,
          radius,
          url,
          externalMessage,
          link,
          linkText,
          headingColor,
          subHeadingColor,
          captionColor,
          bodyColor,
          headingBackground,
          subHeadingBackground,
          captionBackground,
          bodyBackground,
          externalLink
        } = card || {}

        return (
          <MediaCard
            key={_key}
            i={i}
            title={heading}
            subTitle={subHeading}
            caption={caption}
            body={body}
            link={link}
            linkText={linkText}
            align={align}
            url={url}
            imageTitleOverlay={imageTitleOverlay}
            forcedTitleHeight={forcedTitleHeight}
            forcedSubTitleHeight={forcedSubTitleHeight}
            forcedCaptionHeight={forcedCaptionHeight}
            forcedBodyHeight={forcedBodyHeight}
            externalMessage={externalMessage}
            alt={imageAlt}
            imageId={image?.asset?._ref || image?.asset?.id}
            height={height}
            width={width}
            radius={radius}
            titleColor={headingColor}
            subTitleColor={subHeadingColor}
            captionColor={captionColor}
            bodyColor={bodyColor}
            titleBackground={headingBackground}
            subTitleBackground={subHeadingBackground}
            captionBackground={captionBackground}
            bodyBackground={bodyBackground}
            externalLink={externalLink}
            titleSize="1"
            subTitleSize="0.8"
            captionSize="0.6"
            bodySize="0.7"
          />
        )
      })}
    </MediaCardGrid>
  )
}

const gridStyles = css`
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  max-width: ${sizes.constrainWidth};
  padding: 0 3rem;
  a {
    text-decoration: none;
  }
`
