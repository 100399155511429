import React from 'react'
import { css } from '@emotion/core'
import BlockContent from '../block-content'
import { sizes } from '../../styles/variables'

export default function Flex(props) {
  return (
    <BlockContent
      css={[
        styles(props?.node?.inside),
        `column-gap:${props?.node?.gap ? props?.node?.gap : `0`}rem;`
      ]}
      blocks={props?.node?.flexContent}
    />
  )
}

const styles = inside => css`
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  ${!inside &&
  `
            max-width: ${sizes.constrainWidth};
            margin: auto;
            padding: 0 3rem;
        `}
  div {
    padding: 0 !important;
  }
`
