import React from 'react'
import { Scatter } from 'react-chartjs-2'

export default function ScatterGraph(props) {
  const { datasets, width, height, legend, hideLegend, title } = props?.graph
  const data = {
    datasets: datasets.map(dataset => {
      return {
        label: dataset.label,
        borderWidth: dataset.borderWidth,
        pointStyle: dataset.pointStyle,
        pointRadius: dataset.pointRadius,
        pointHoverRadius: dataset.pointRadius + 1,
        data: dataset.data.map(data => {
          return {
            x: data?.x,
            y: data?.y
          }
        }),
        backgroundColor: dataset?.backgroundColor,
        borderColor: dataset?.borderColor,
        hoverBackgroundColor: dataset?.hoverBackgroundColor,
        hoverBorderColor: dataset?.hoverBorderColor
      }
    })
  }
  const options = {
    title: {
      display: true,
      text: title,
      fontSize: 20
    },
    legend: {
      display: !hideLegend,
      position: legend
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    maintainAspectRatio: false
  }

  return <Scatter data={data} width={width} height={height} options={options} />
}
