import React from 'react'
import Slider from 'react-slick'
import { css } from '@emotion/core'
import { colors } from '../styles/variables'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Carousel(props) {
  const { hideDots, children } = props

  function PrevArrow({ className, onClick }) {
    return (
      <div
        className={className}
        style={{
          display: 'block',
          background: colors.primary,
          borderRadius: '25%'
        }}
        onClick={onClick}
      />
    )
  }

  function NextArrow({ className, onClick }) {
    return (
      <div
        className={className}
        style={{
          display: 'block',
          background: colors.primary,
          borderRadius: '25%'
        }}
        onClick={onClick}
      />
    )
  }

  const settings = {
    dots: !hideDots,
    infinite: true,
    arrows: hideDots,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  }

  if (!children) return null

  return (
    <div css={styles}>
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

const styles = css`
  color: #fff;
  margin: 0 0 50px 0;
  .slick-dots {
    bottom: 10px;
    .slick-active {
      button {
        :before {
          opacity: 1;
          background-color: ${colors.white};
        }
      }
    }
    li {
      background: ${colors.secondaryLighter};
      margin: 0px;
      height: 22px;
      width: 22px;
      :first-of-type {
        border-radius: 8px 0 0 8px;
      }
      :last-of-type {
        border-radius: 0px 8px 8px 0;
      }
      button {
        :before {
          opacity: 1;
          content: '';
          background-color: ${colors.secondary};
          border-radius: 50%;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .icon {
    color: ${colors.primary};
  }
`
