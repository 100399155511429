import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { css } from '@emotion/core'
import SEO from '../seo'
import { colors, fonts, breakpoints, sizes } from '../../styles/variables'

export default function IdeaSubmission() {
  return (
    <>
      <SEO title="Idea Submission" />
      <div css={styles} id="idea-submission">
        <form
          method="post"
          data-netlify="true"
          name="ideasaction"
          data-netlify-honeypot="bot-field"
          action="/idea-submitted"
        >
          <input type="hidden" name="form-name" value="ideasaction" />
          <input type="hidden" name="bot-field" />
          <input type="hidden" value="s" name="check" />
          <div className="section">
            <h3>Contact Information:</h3>
            <div className="row">
              <span className="req">*</span>
              <input placeholder="Name" type="text" name="name" required />
            </div>
            <div className="row">
              <span className="not">*</span>
              <input placeholder="Address" type="text" name="address" />
            </div>
            <div className="row">
              <span className="not">*</span>
              <input placeholder="Country" type="text" name="country" />
            </div>
            <div className="row">
              <span className="req">*</span>
              <input placeholder="Email" type="email" name="email" required />
            </div>
            <div className="row">
              <span className="req">*</span>
              <input placeholder="Phone" type="text" name="phone" required />
            </div>
          </div>
          <div className="section">
            <h3>Product Information:</h3>
            <div className="row">
              <span className="req">*</span>
              <input
                placeholder="Product Name"
                type="text"
                name="productname"
                required
              />
            </div>
            <div className="row">
              <span className="req">*</span>
              <label>Product Description</label>
              <textarea name="productdesc" required />
            </div>
            <div className="row">
              <label>Product's Existing Markets</label>
              <textarea name="productmarkets" />
            </div>
            <div className="row">
              <label>Product Ideas</label>
              <textarea name="productideas" />
            </div>
            <div className="row">
              <label>Product Patent Links</label>
              <textarea name="productpatents" />
            </div>
            <div className="row">
              <label>Product Media Links</label>
              <textarea name="productmedia" />
            </div>
          </div>
          <div className="section">
            <div className="row">
              <span className="req">*</span>
              <input type="checkbox" name="agree" required />
              <label className="check">
                I have read and agree to all the terms and conditions of
                Escalade's &nbsp;
                <AnchorLink to="/new-ideas#idea-policy">
                  Unsolicited&nbsp;Idea&nbsp;Submission&nbsp;Policy
                </AnchorLink>
                &nbsp;and &nbsp;
                <AnchorLink to="/new-ideas#idea-submission">
                  Terms&nbsp;of&nbsp;Submission
                </AnchorLink>
                &nbsp;in their entirety.
              </label>
            </div>
          </div>
          <input type="submit" value="Submit Idea" />
        </form>
      </div>
    </>
  )
}

const styles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
  @media (${breakpoints.phoneLarge}) {
    margin: auto;
    max-width: ${sizes.constrainWidth};
    padding: 0 3rem;
  }
  h2 {
    color: ${colors.primary};
    font-family: ${fonts.primary};
  }
  .section {
    margin-bottom: 1rem;
    h3 {
      font-size: 1.25rem;
      font-family: ${fonts.primary};
    }
    .row {
      display: table-row;
    }
    input[type='text'],
    input[type='email'] {
      font-family: ${fonts.primary};
      font-weight: 300;
      width: 20rem;
      margin-bottom: 1rem;
      font-size: 0.9rem;
    }
    input[type='submit'] {
      font-family: ${fonts.primary};
    }
    textarea {
      width: 100%;
      height: 10rem;
      font-family: ${fonts.primary};
      font-weight: 300;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      resize: none;
      @media (${breakpoints.tablet}) {
        width: 150%;
      }
    }
    label {
      font-size: 0.9rem;
      font-family: ${fonts.primary};
    }
    .check {
      margin-left: 0.25rem;
      font-size: 0.75rem;
    }
  }
  .req {
    color: red;
    font-size: 1.05rem;
  }
  .not {
    color: white;
    font-size: 1.05rem;
  }
`
