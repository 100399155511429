import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Modal from './modal'
import { colors, fonts, breakpoints } from '../styles/variables'

export default function Alert(props) {
  const { text, link } = props

  const linkProps = link ? { to: link, as: Link } : {}
  const Tag = link ? Link : `a`

  const internal = /^\/(?!\/)/.test(link)

  return (
    <Banner>
      <span>
        {text}&emsp;
        {internal ? (
          <Tag {...linkProps} href={link} target="_blank">
            READ MORE HERE
          </Tag>
        ) : (
          <Modal cursor="pointer" to={link} text={'READ MORE HERE'} />
        )}
      </span>
    </Banner>
  )
}

const Banner = styled.div`
  padding: 0.5rem 0;
  background-color: ${colors.primary};
  color: ${colors.white};
  font-family: ${fonts.primary};
  font-weight: bold;
  font-size: 8px;
  text-align: center;
  height: 40px;
  a {
    color: ${colors.white};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
  @media (${breakpoints.phoneSmall}) {
    font-size: 0.5rem;
  }
  @media (min-width: 480px) {
    font-size: 0.75rem;
  }
  @media (${breakpoints.tablet}) {
    font-size: 0.9rem;
  }
`
