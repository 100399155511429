import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import BlockContent from './block-content'
import ContentButtons from './content-buttons'
import IconTile from './icon-tile'
import { colors, fonts, breakpoints, sizes } from '../styles/variables'

export default function FeatureContent(props) {
  const [windowWidth, setWidth] = useState(0)

  useEffect(() => {
    window && setWidth(window.innerWidth)
  })

  const {
    anchor,
    imageRight,
    className,
    content,
    media,
    background,
    topHeading,
    heading,
    height,
    width,
    radius,
    subheading,
    brand,
    horizontalHeadings,
    children
  } = props

  return windowWidth > 1023 ? (
    <div id={anchor} css={styles(props)} className={className}>
      {!imageRight ? (
        <>
          {(media || background) && (
            <div className="media">
              <IconTile
                className="iconTile"
                media={media}
                background={background}
                heading={heading}
                height={height}
                width={width}
                radius={radius}
              />
            </div>
          )}
          <div className={`content ${horizontalHeadings ? `horizontal` : ``}`}>
            {topHeading && (
              <h4 className="title-feature" css={headingStyles(topHeading)}>
                {topHeading?.text}
              </h4>
            )}
            {heading && (
              <h1 className="title-feature" css={headingStyles(heading)}>
                {heading?.text}
              </h1>
            )}
            {subheading && (
              <h2 className="title-feature" css={headingStyles(subheading)}>
                {subheading?.text}
              </h2>
            )}
            {content && (
              <BlockContent renderContainerOnSingleChild blocks={content} />
            )}
            {children}
            {brand && <ContentButtons brand={brand} />}
          </div>
        </>
      ) : (
        <>
          <div className={`content ${horizontalHeadings ? `horizontal` : ``}`}>
            {heading && <h1 css={headingStyles(heading)}>{heading?.text}</h1>}
            {subheading && (
              <h2 css={headingStyles(subheading)}>{subheading?.text}</h2>
            )}
            {content && (
              <BlockContent renderContainerOnSingleChild blocks={content} />
            )}
            {children}
            {brand && <ContentButtons brand={brand} />}
          </div>
          {(media || background) && (
            <div className="media">
              <IconTile
                className="iconTile"
                media={media}
                background={background}
                heading={heading}
                height={height}
                width={width}
                radius={radius}
              />
            </div>
          )}
        </>
      )}
    </div>
  ) : (
    <div
      id={anchor}
      css={styles({ ...props, windowWidth })}
      className={className}
    >
      {(media || background) && (
        <div className="media">
          <IconTile
            className="iconTile"
            media={media}
            background={background}
            heading={heading}
            height={height}
            width={width}
            radius={radius}
          />
        </div>
      )}
      <div className={`content ${horizontalHeadings ? `horizontal` : ``}`}>
        {topHeading && (
          <h4 className="title-feature" css={headingStyles(topHeading)}>
            {topHeading?.text}
          </h4>
        )}
        {heading && (
          <h1 className="title-feature" css={headingStyles(heading)}>
            {heading?.text}
          </h1>
        )}
        {subheading && (
          <h2 className="title-feature" css={headingStyles(subheading)}>
            {subheading?.text}
          </h2>
        )}
        {content && (
          <BlockContent
            className="content-feature"
            renderContainerOnSingleChild
            blocks={content}
          />
        )}
        {children}
        {brand && <ContentButtons brand={brand} />}
      </div>
    </div>
  )
}

const headingStyles = props => css`
  color: ${props.color || `black`};
  text-align: ${props.align || `left`};
  ${props.size && `font-size: ${props.size}rem !important;`}
  font-weight: ${props.bold ? 'bold' : 'normal'};
  font-style: ${props.italic ? 'italic' : 'normal'};
  font-family: ${fonts.primary};
`

const styles = props => css`
  display: block;
  padding: 0 1rem;
  ${!props?.cardInternal &&
  `
      @media(${breakpoints.phoneLarge}) {
        padding: 0 3rem;
        max-width: ${sizes.constrainWidth};
        margin: auto;
      }
    `}
  color: ${colors.black};
  font-family: ${fonts.primary};
  ${!props?.cardInternal &&
  `
      ${
        (!props?.centeredContent || props?.media) &&
        !props?.bodyColor &&
        `
          ${
            props.content &&
            `
              > * {
                ${props.background ?
                  'margin: 0 1rem 1rem;' : 'margin: 0 0 1rem;'}
              }
            `
          }
          `
      }
    `}
  ${!props?.cardInternal &&
  `
    .title-feature {
      width: 100%;
    }
  `}
  .content {
    ${props?.bodyColor && `padding: 1rem;`}
    width: 100%;
    height: auto;
    max-width: ${props?.windowWidth}px;
    @media (${breakpoints.phoneLarge}) {
      max-width: ${props?.windowWidth - 108}px;
    }
    background: ${props?.bodyColor};
    ${props?.bodyColor && 'line-height: 20px;'}
    .content-feature, p {
      ${props?.fontSize && `font-size: ${props?.fontSize}rem;`}
      ${props.bodyAlign && `text-align: ${props.bodyAlign};`}
    }
    @media (${breakpoints.laptop}) {
      height: ${props?.forcedHeight ? `${props?.forcedHeight}px` : 'auto'};
    }
  }
  .media {
    z-index: 1;
  }
  h1,
  h2,
  h4 {
    @media(${breakpoints.phoneLarge}) {
      line-height: 150%;
  }
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;
  }
  h4 {
    margin-bottom: 0.5rem;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  ${!props?.cardInternal
    ? `
      p {
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        colors: ${colors.secondary};
        ${
          props.bodyColor &&
          `
            @media(${breakpoints.laptop}) {
              font-size: 8px;
            }
            @media(min-width: 1030px) {
              font-size: 9px;
            }
            @media(min-width: 1052px) {
              font-size: 10px;
            }
            @media(min-width: 1095px) {
              font-size: 12px;
            }
            @media(min-width: 1139px) {
              font-size: 14px;
            }
          `
        }
      }
    `
    : `
    p {
      @media(${breakpoints.phoneLarge}) {
          line-height: 150%;
      }
      font-size: 0.775rem;
      margin: 0;
      margin-bottom: 10px;
      colors: ${colors.secondary};
      font-weight: light;
      // @media(${breakpoints.tablet}) {
      //   font-size: 0.333rem;
      // }
      // @media(min-width: 828px) {
      //   font-size: 0.37rem;
      // }
      // @media(min-width: 985px) {
      //   font-size: 0.44rem;
      // }
      // @media(min-width: 1095px) {
      //   font-size: 0.515rem;
      // }
      // @media(min-width: 1212px) {
      //     font-size: 0.55rem;
      // }
      // @media(min-width: 1600px) {
      //   font-size: 0.58rem;
      // }
      @media(${breakpoints.tablet}) {
        font-size: 0.444rem;
      }
      @media(min-width: 828px) {
        font-size: 0.5rem;
      }
      @media(min-width: 985px) {
        font-size: 0.6rem;
      }
      @media(min-width: 1095px) {
        font-size: 0.668rem;
      }
      @media(min-width: 1212px) {
          font-size: 0.75rem;
      }
      @media(min-width: 1600px) {
        font-size: 0.85rem;
      }
    }
    `}
  .horizontal {
    h1 {
      margin-right: 10px;
    }
    h1,
    h2 {
      display: inline-block;
      font-size: 1.25em;
    }
  }
  ${!props?.cardInternal &&
  `
      @media(${breakpoints.laptop}) {
        display: flex;
        align-items: center;
      }
    `}
`
