import React from 'react'
import { Link } from 'gatsby'
import Modal from '../modal'
import getStyles from './get-styles'

export default function Button(props) {
  const {
    link,
    icon,
    externalMessage,
    text,
    align,
    secondary,
    light,
    outlined,
    hoverTextColor,
    fontSize,
    noFlex,
    ...otherProps
  } = props

  const linkProps = link ? { to: link, as: Link } : {}
  const Tag = link ? Link : `div`

  const internal = /^\/(?!\/)/.test(link)

  const styles = getStyles({
    align,
    secondary,
    light,
    outlined,
    hoverTextColor,
    fontSize,
    text,
    icon,
    noFlex
  })

  return internal ? (
    !noFlex ? (
      <div css={styles}>
        <Tag className="tag" {...linkProps} {...otherProps}>
          {icon || text || `Learn More`}
        </Tag>
      </div>
    ) : (
      <Tag css={styles} {...linkProps} {...otherProps}>
        {icon || text || `Learn More`}
      </Tag>
    )
  ) : !noFlex ? (
    <div css={styles}>
      <Modal
        className={'tag'}
        to={link}
        text={text}
        externalMessage={externalMessage}
      />
    </div>
  ) : (
    <Modal
      css={styles}
      to={link}
      text={icon || text}
      externalMessage={externalMessage}
    />
  )
}
